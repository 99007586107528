import { Card } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useQuizContext } from '../quizContext';
import scorepic from './../images/score_performance.png';
import durationpic from './../images/hourglass.png';
import timer from './../images/timer.json';
import Lottie from "lottie-react";

function PalletteRender() {
    const { duration, setDuration, pallette, setPallette, completed, setCompleted, score, setScore,  currentQuestionIndex,
      setCurrentQuestionIndexMain } =  useQuizContext()
  const [timeLeft, setTimeLeft] = useState(15 * 60); // 15 minutes in seconds

  const handleQuestionClick = (index) => {
    setCurrentQuestionIndexMain(index);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const questionNumbers = [1, 2, 3, 4, 5, 6];

  return (
    <Card elevation={10}  style={styles.container}>
     {completed ? <div style={styles.timerContainer}>
     <img src={durationpic} style={{height:50,width:50}}></img>
        <h2 style={styles.timerTitle}>Time Taken</h2>
        <div style={styles.timerDisplay}>{formatTime(duration)}</div>
      </div> : <div style={styles.timerContainer}>
      {/* <Lottie animationData={timer} style={{width:50,height:50}} loop={true} /> */}
        <h2 style={styles.timerTitle}>Time Remaining</h2>
        <div style={styles.timerDisplay}>{formatTime(timeLeft)}</div>
      </div>
}

{completed && <div> 
    <img src={scorepic} style={{height:50,width:50}}></img>
    <h2 style={styles.timerTitle}>Total Score</h2>
        <div style={styles.timerDisplay}>{score}/18</div>
      </div>}
      <div style={styles.paletteContainer}>
        <h2 style={styles.paletteTitle}>Question Palette</h2>
        <div style={styles.buttonContainer}>
          {questionNumbers.map((num,index) => (
            <div   onClick={() => handleQuestionClick(index)} key={num} style={(pallette && pallette.length>0 && !pallette[num-1]) ? styles.circleButtonUnselected: styles.circleButton}>
              {num}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#fff',
    height: 'auto',
    display: 'flex',
    marginTop:100,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius:10
  },
  timerContainer: {
    marginBottom: '30px',
    textAlign: 'center',
  },
  timerTitle: {
    fontSize: '20px',
    marginBottom: '10px',
  },
  timerDisplay: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#333',
  },
  paletteContainer: {
    width: '100%',
  },
  paletteTitle: {
    fontSize: '20px',
    marginBottom: '15px',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '10px',
  },
  circleButton: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#7f388b',
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    display: 'flex',
    borderWidth:1,
    borderColor:"#bdbdbd",
    borderStyle:"solid",
    padding:16,
    justifyContent: 'center',
    alignItems: 'center',
    cursor:"pointer"
  },
  circleButtonUnselected: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    color: 'black',
    borderWidth:1,
    padding:16,
    borderColor:"#bdbdbd",
    borderStyle:"solid",
    fontSize: '18px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor:"pointer"

  },
};

export default PalletteRender;