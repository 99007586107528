import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';


// Initialize Mixpanel
function Classroom({isLoggedIn, user,token}) {


  
    const location = useLocation();
    const { data } = location.state || {}; 

    const navigation =  useHistory()

    useEffect(()=>{
        if(!isLoggedIn){
            navigation.replace("/")
        }
    },[isLoggedIn])


  const [questionsData, setQuestionsData] = useState([]);
  const [wordsChangingContext, setWordsChangingContext] = useState([]);

  useEffect(() => {
    if (data) {
        console.log(data)
      setQuestionsData(JSON.parse(data.explanation));
      setWordsChangingContext(JSON.parse(data.wordsChangingContext));
    }
  }, [data]);

  const renderSentence = (sentence, index) => {
    const trimmedSentence = sentence.trim().toLowerCase();
    const foundPhrase = wordsChangingContext.find(phrase => trimmedSentence.includes(phrase.toLowerCase()));
    return (
      <span key={index} style={{ color: foundPhrase ? 'red' : 'inherit' }}>
        {sentence + ' '}
      </span>
    );
  };

  const renderCard = (item, index) => (
    <div key={index} style={styles.card}>
      <p style={styles.passage}>
        {item.content.split('.').map((sentence, sentenceIndex) => renderSentence(sentence, sentenceIndex))}
      </p>
      <ClassroomBox explanation={item.explanation} tip={item.tip} />
    </div>
  );

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.headerTitle}>Class Room</h1>
      </div>
      <div style={styles.scrollContainer}>
        {questionsData.map((item, index) => renderCard(item, index))}
      </div>
    </div>
  );
};

const ClassroomBox = ({ explanation, tip }) => {
  return (
    <div style={styles.boxCard}>
      <div>
        <h3 style={styles.boxTitle}>EXPLANATION</h3>
        <p style={styles.boxText}>{explanation}</p>
      </div>
      <div style={styles.tipContainer}>
        <div style={styles.tipHeader}>
          <span style={styles.personIcon}>👤</span>
          <h4 style={styles.tipTitle}>Toppers tip</h4>
        </div>
        <p style={styles.tipText}>{tip}</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f2ebf3',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    padding: '16px',
    textAlign:"center",
    justifyContent:"center",
    alignItems:"center"

  },
  backButton: {
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0 0 0 16px',
    textAlign:"center",
    color:"black"
  },
  scrollContainer: {
    padding: '16px',
    overflowY: 'auto',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  passage: {
    fontSize: '18px',
    lineHeight: '1.6',
    marginBottom: '16px',
  },
  boxCard: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '16px',
    marginTop: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  boxTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#7f388b',
    fontSize: '14px',
    marginBottom: '8px',
  },
  boxText: {
    textAlign: 'center',
    color: '#000',
    fontSize: '16px',
  },
  tipContainer: {
    marginTop: '16px',
  },
  tipHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  personIcon: {
    fontSize: '24px',
    marginRight: '8px',
  },
  tipTitle: {
    fontWeight: 'bold',
    color: '#7f388b',
    fontSize: '14px',
    margin: 0,
  },
  tipText: {
    color: '#000',
    fontSize: '14px',
  },
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
    token:state.auth.token
});

export default connect(mapStateToProps)(Classroom);