import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import gsap from 'gsap';

const GlobalStyle = createGlobalStyle`
  :root {
    --color: #40407a;
    --font-color: #f1c40f;  
    --rocket-main: white;
    --rocket-highlight: #7f388b;
    --rocket-glass: #9AECDB;
    --rocket-smoke: #f1f2f6;
    --rocket-fire: #f0932b;
    --rocket-fire-highlight: #f1c40f;
  }

  html, body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background: #000;
  }
`;

const Frame = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Rocket = styled.div`
  position: relative;
  width: 27.5px; /* half of original width */
  height: 50px; /* half of original height */

  .rocket__body {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10;

    &__window {
      position: absolute;
      width: 11px; /* half of original window size */
      height: 11px;
      top: 12.5px;
      left: 7px;
      border-radius: 50%;
      background: var(--rocket-glass);
      z-index: 1;
      border: 1.5px solid var(--rocket-highlight); /* half of original border */
    }

    &__inner {
      position: relative;
      width: 27.5px; /* half of original width */
      height: 62.5px; /* half of original height */
      border-radius: 80%;
      background: var(--rocket-main);
      overflow: hidden;

      &:before,
      &:after {
        content: "";
        position: absolute;
        background: var(--rocket-highlight);
        border-radius: 50%;
      }

      &:before {
        width: 100%;
        height: 25px; /* half of original height */
        top: -15px; /* half of original top offset */
      }

      &:after {
        width: 100%;
        height: 5px; /* half of original height */
        bottom: 7.5px; /* half of original bottom offset */
      }
    }
  }

  .rocket__wing {
    position: absolute;
    background: var(--rocket-highlight);
    bottom: 0;
    z-index: 5;

    &--left {
      left: -14.5px; /* half of original left offset */
      width: 22px; /* half of original width */
      height: 10px; /* half of original height */
      border-radius: 100% 0 0 0;
      transform: rotate(-50deg);
    }

    &--right {
      right: -14.5px; /* half of original right offset */
      width: 22px; /* half of original width */
      height: 10px; /* half of original height */
      border-radius: 0 100% 0 0;
      transform: rotate(50deg);
    }
  }

  .rocket__label {
    width: 125px; /* half of original width */
    position: absolute;
    top: -40px; /* half of original top offset */
    left: -40px; /* half of original left offset */

    p {
      position: absolute;
      top: 0;
      left: 0;
      font-family: 'Bowlby One SC', cursive;
      font-size: 40px; /* half of original font size */
      line-height: 40px; /* half of original line height */
      margin: 0;
      text-transform: uppercase;
      color: var(--font-color);
      transform: rotate(-25deg);
    }
  }

  .rocket__smoke {
    position: absolute;
    width: 25px; /* half of original width */
    height: 5px; /* half of original height */
    bottom: 2.5px; /* half of original bottom offset */
    opacity: 0;
    z-index: 15;

    &__inner {
      position: relative;
      margin: 15px 0 0 0; /* half of original margin */
      width: 100%;
      height: 100%;
      background: var(--rocket-smoke);

      div {
        position: absolute;
        border-radius: 50%;
        width: 6px; /* half of original width */
        height: 6px; /* half of original height */
        left: -2.5px; /* half of original left offset */
        bottom: 0;
        box-shadow: inset -1px -1.5px 0 0 var(--rocket-smoke); /* half of original shadow */
        background: #fff;
        z-index: 10;

        &:nth-child(1) {
          transform: scale(1.5);
          left: 10%;
          bottom: 30%;
          z-index: 9;
        }

        &:nth-child(2) {
          transform: scale(2.5);
          left: 50%;
          bottom: 90%;
          z-index: 8;
        }

        &:nth-child(3) {
          transform: scale(1.1);
          left: 84%;
          bottom: 4.5%;
          z-index: 7;
        }
      }
    }

    &--left {
      left: -25px; /* half of original left offset */
      transform: rotateY(180deg);
    }

    &--right {
      right: -25px; /* half of original right offset */
    }
  }

  .rocket__fire {
    position: absolute;
    width: 6.875px; /* half of original width */
    height: 6.875px; /* half of original height */
    bottom: -7.875px; /* half of original bottom offset */
    left: 10.375px; /* half of original left offset */
    background: var(--rocket-fire);
    border-radius: 80% 0 55% 50% / 55% 0 80% 50%;
    margin-top: 20px;
    transform: rotate(135deg);

    &:after {
      content: "";
      position: absolute;
      top: 1.5px; /* half of original top offset */
      left: 0.5px; /* half of original left offset */
      width: 5.155px; /* half of original width */
      height: 5.155px; /* half of original height */
      background: var(--rocket-fire-highlight);
      border-radius: 80% 0 55% 50% / 55% 0 80% 50%;
    }
  }
`;

const Shadow = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  background: rgba(100, 100, 100, .1);
  z-index: 1;

  &.shadow--full {
    width: 100%;
  }
`;

const RocketComponent = () => {
  useEffect(() => {
    let tl = gsap.timeline({ repeat: -1, repeatDelay: 1 }),
      labels = document.getElementsByClassName('labels'),
      rocket = document.querySelectorAll('.rocket__body, .rocket__wing, .rocket__fire'),
      smokeL = document.querySelectorAll('.rocket__smoke--left'),
      smokeR = document.querySelectorAll('.rocket__smoke--right'),
      fire = document.getElementsByClassName('rocket__fire');

    let cdStart = 1.25,
      cdLeave = cdStart / 2,
      esDuration = 0.10,
      esRepeat = 15,
      smDuration = 1.5;

    tl.addLabel('countdown')
      .from(labels, {
        duration: cdStart,
        scale: 0,
        x: '50px',
        y: '50px',
        stagger: cdStart / labels.length,
      }, 'countdown')
      .to(labels, {
        duration: cdLeave,
        scale: 0,
        x: '20px',
        y: '20px',
        opacity: 0,
        stagger: cdStart / labels.length,
      }, 'countdown+=' + cdStart / labels.length)
      .addLabel('engine-start')
      .from(rocket, {
        duration: esDuration,
        x: '+=3px',
        repeat: esRepeat,
      }, 'engine-start-=.5')
      .from(rocket, {
        duration: esDuration * 20,
        y: '+=5px',
      }, 'engine-start-=1')
      .from(smokeL, {
        duration: smDuration,
        scale: 0,
        opacity: 2,
        stagger: smDuration / smokeL.length,
        x: '+=45px',
        y: '+=30px',
      }, 'engine-start-=.5')
      .from(smokeR, {
        duration: smDuration,
        scale: 0,
        opacity: 2,
        stagger: smDuration / smokeR.length,
        x: '-=45px',
        y: '+=30px',
      }, 'engine-start-=.5')
      .from(fire, {
        duration: smDuration,
        scale: 0,
      }, 'engine-start-=.5')
      .addLabel('lift-off')
      .to(rocket, {
        duration: 2,
        y: '-=100px',
      }, 'lift-off-=1')
      .to(fire, {
        duration: .25,
        scale: 2,
      }, 'lift-off-=1')
      .addLabel('launch')
      .to(rocket, {
        duration: 3,
        y: () => '-=' + document.body.offsetHeight + 'px',
        ease: 'power4',
      }, 'launch-=1.5')
      .to(fire, {
        duration: .25,
        scale: 1.75,
        repeat: 10,
      }, 'launch-=1.8');
  }, []);

  return (
    <>
      <GlobalStyle />
      <Frame>
        <Rocket>
          <div className="rocket__body">
            <div className="rocket__body__window">
              <Shadow className="shadow" />
            </div>
            <div className="rocket__body__inner">
              <Shadow className="shadow" />
            </div>
          </div>
          <div className="rocket__wing rocket__wing--left" />
          <div className="rocket__wing rocket__wing--right">
            <Shadow className="shadow shadow--full" />
          </div>
          <div className="rocket__label">
            {[3, 2, 1].map((val) => (
              <p className="labels" key={val}>{val}</p>
            ))}
          </div>
          {['left', 'right'].map((val) => (
            [1, 1, 1, 1, 1].map((_, index) => (
              <div key={`${val}-${index}`} className={`rocket__smoke rocket__smoke--${val}`}>
                <div className="rocket__smoke__inner">
                  {[1, 1, 1, 1].map((_, index) => (
                    <div key={index} />
                  ))}
                </div>
              </div>
            ))
          ))}
          <div className="rocket__fire" />
        </Rocket>
      </Frame>
    </>
  );
};

export default RocketComponent;
