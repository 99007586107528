import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Spinner } from 'react-bootstrap';
import { Snackbar } from '@mui/material';
import axios from 'axios';
import './Login.css';
import { encryptData } from './encryption';
import { useQuizContext } from '../quizContext';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect, useSelector } from 'react-redux';
import { login } from '../authSlice';
import { loginSuccess } from '../authActions';
import { apiendpoint } from '../endpoint';


const LoginComponent = ({isLoggedIn}) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const navigate = useHistory();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [showOtpField, setShowOtpField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [jwtoken, setjwToken] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  useEffect(()=>{
    if(isLoggedIn){
        if(navigate.push){
            navigate.replace('/home')
        }
    }
  },[isLoggedIn])

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(apiendpoint.path+'/v1/auth/loginweb', { email });
      setShowOtpField(true);
      setSuccess('OTP sent to your email.');
      setjwToken(response.data.jwtToken);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to send OTP. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (otp.length !== 6 || !/^\d+$/.test(otp)) {
      setError('Please enter a valid 6-digit OTP.');
      return;
    }
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(apiendpoint.path+'/v1/auth/otpvalidate', { email, otp, token:jwtoken });
      setSuccess('Login successful!');
      const encryptedData = encryptData(response.data);
    localStorage.setItem('userData', encryptedData);
    
    // You can also store the access token separately if needed
    localStorage.setItem('accessToken', response.data.accessToken);
    dispatch(loginSuccess(
      response.data.accessToken,
      response.data.user
      ));
    } catch (error) {
        console.log(error)
      setSnackbar({ open: true, message: 'Invalid OTP. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="header">
          <img src="http://storage.googleapis.com/assets_verboace/55837909.png" style={{height:100,width:100}} alt="Verboace" className="logo" />
          <div style={{fontSize:20,fontWeight:"bold",marginBottom:50}}>Student login is available only for paid subscribers</div>
        </div>
     
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        {!showOtpField ? (
          <Form onSubmit={handleEmailSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading} className="submit-button">
              {loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-2" />
                  Sending...
                </>
              ) : (
                'Send OTP'
              )}
            </Button>
          </Form>
        ) : (
          <Form onSubmit={handleOtpSubmit}>
            <Form.Group controlId="formBasicOtp">
              <Form.Label>OTP</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter 6-digit OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                maxLength={6}
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading} className="submit-button">
              {loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-2" />
                  Verifying...
                </>
              ) : (
                'Verify OTP'
              )}
            </Button>
          </Form>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      />
    </div>
  );
};


const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user
});

export default connect(mapStateToProps)(LoginComponent);