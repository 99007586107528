import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const UserDevicesLineGraph = ({ data }) => {
  const formatXAxis = (tickItem) => {
    // Assuming tickItem is a valid date string or timestamp
    const date = new Date(tickItem);
    return date.toLocaleDateString(undefined, { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data.results} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
        <XAxis 
          dataKey="date_opened" 
          type="category" 
          interval={0} 
          angle={0} 
          textAnchor="end" 
          height={70} 
          tickFormatter={formatXAxis}
        />
        <YAxis type="number" domain={[0, 'dataMax + 10']} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip 
          labelFormatter={(value) => formatXAxis(value)}
        />
        <Legend />
        <Line type="monotone" dataKey="users_opened" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default UserDevicesLineGraph;