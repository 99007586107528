import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Lottie from 'react-lottie';
import animationData from '../images/loading.json';
import verboace from '../images/verboace.png';

Modal.setAppElement('#root');

const ConversationModal = ({ isOpen, onClose, weakArea, weakCategory }) => {
    console.log(weakArea)
    console.log(weakCategory)
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (isOpen && weakArea && weakCategory) {
      setMessages([]);
      const newMessages = [
        "It's your first test for today so Verboace coach will pick a test for you.",
        `It seems like you are weak in ${weakArea}.`,
        `Your weak category seems to be ${weakCategory}.`,
        "Picking a test to patch up these. All the best!"
      ];
      
      newMessages.forEach((message, index) => {
        setTimeout(() => setMessages(prev => [...prev, message]), (index + 1) * 2500);
      });
      
      setTimeout(() => onClose(), 12000);
    }
  }, [isOpen, weakArea, weakCategory]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onRequestClose={onClose} 
      contentLabel="Conversation Modal"
      style={customStyles}
    >
      <div style={styles.modalContent}>
        <div style={styles.lottieContainer}>
          <Lottie options={defaultOptions} height={150} width={'auto'} />
        </div>
        <div style={styles.messageContainer}>
          {messages.map((message, index) => (
            <div key={index} style={styles.messageWrapper}>
              <div style={styles.profilePic}>
                <img src={verboace} alt="Profile" style={styles.profileImg} />
              </div>
              <div style={styles.message}>
                {message}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    height: '500px',
    padding: '20px',
    borderRadius: '20px',
    backgroundColor: '#f5f5f5',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
    border: 'none',
    zIndex: 1001,
  },
};

const styles = {
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  lottieContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  messageContainer: {
    flex: 1,
    overflowY: 'auto',
    paddingRight: '10px',
  },
  messageWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '12px',
  },
  profilePic: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: '10px',
    flexShrink: 0,
  },
  profileImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    backgroundColor:"#fff"
  },
  message: {
    backgroundColor: '#ffffff',
    color: '#333333',
    borderRadius: '18px',
    padding: '12px 16px',
    maxWidth: 'calc(100% - 50px)',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.05)',
    fontSize: '14px',
    lineHeight: '1.4',
  },
};

export default ConversationModal;