import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiendpoint } from './endpoint';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { Pagination } from '@mui/material';
import { Snackbar, CircularProgress } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const NotifyAdmin = ({ isLoggedIn, user, token }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigation = useHistory();
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState('');
  const [title, setTitle] = useState('');
  const [severity,setseverity] = useState('success')
  const [message,setmessage] = useState('')
  const [content, setContent] = useState('');
  const [everyone, setEveryone] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [notificationTokens, setNotificationTokens] = useState([]);
  const [errors, setErrors] = useState({});
  const [sendingNotification, setSendingNotification] = useState(false);
const [snackbarOpen, setSnackbarOpen] = useState(false);

  const usersPerPage = 10;

  useEffect(() => {
    if (!isLoggedIn || !user || !user.verified) {
      navigation.replace("/");
    }
  }, [isLoggedIn, user, navigation]);

  useEffect(() => {
    fetchUsers(currentPage, search);
  }, [currentPage, search, token]);

  const fetchUsers = async (page, search) => {
    try {
      setLoading(true);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(`${apiendpoint.path}/v1/admin/getusers`, {
        page: page + 1,
        limit: usersPerPage,
        search,
      }, { headers });
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
      setTotalUsers(response.data.total);
    } catch (error) {
      console.error('Error fetching users:', error);
      navigation.replace("/");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0);
  };

  const handleSelectUser = (user) => {
    console.log(user)
    if (!selectedUsers.includes(user)) {
      setSelectedUsers([...selectedUsers, user]);
      setNotificationTokens([...notificationTokens, user.notification_token]);
    }
  };

  const handleRemoveUser = (user) => {
    setSelectedUsers(selectedUsers.filter(u => u !== user));
    setNotificationTokens(notificationTokens.filter(token => token !== user.notification_token));
  };

  const validateForm = () => {
    const errors = {};
    if (!title) errors.title = 'Title is required';
    if (!content) errors.content = 'Content is required';
    if (!everyone && selectedUsers.length === 0) errors.users = 'Select at least one user or choose "Send to Everyone"';
    return errors;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setseverity("warning")
      setmessage('Everyone field or select users')
      setSnackbarOpen(true);
      return;
    }
    try {
      setSendingNotification(true);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(`${apiendpoint.path}/v1/admin/notifyusers`, {
        notification_tokens: notificationTokens,
        title,
        content,
        everyone,
      }, { headers });
      if(response.status==200){
      setseverity('success')
      setmessage('Notifications sent successfully!')
      setSnackbarOpen(true);
      setTitle('');
      setContent('');
      setEveryone(false);
      setSelectedUsers([]);
      setNotificationTokens([]);
      setErrors({});
      }
      else{
        setseverity("warning")
        setmessage('Something went wrong')
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error sending notifications:', error);
      setseverity("warning")
      setmessage('Something went wrong')
      setSnackbarOpen(true);
    } finally {
      setSendingNotification(false);
    }
  };

  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
        <img src="http://storage.googleapis.com/assets_verboace/55837909.png" style={{ height: 100, width: 100 }} alt="Verboace" className="logo" />
      </div>
      <Form onSubmit={handleFormSubmit}>
        <Input
          type="text"
          placeholder="Notification Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <Input
          type="text"
          placeholder="Notification Content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        />
        <CheckboxWrapper>
          <label>
            <input
              type="checkbox"
              checked={everyone}
              style={{padding:10}}
              onChange={() => setEveryone(!everyone)}
            />
            Send to Everyone
          </label>
        </CheckboxWrapper>
        <SubmitButton type="submit" disabled={sendingNotification}>
        {sendingNotification ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          'Send Notification'
        )}
      </SubmitButton>
      </Form>
      <SelectedUsersWrapper>
        {selectedUsers.map(user => (
          <SelectedUser key={user.email} onClick={() => handleRemoveUser(user)}>
            {user.display_name}
          </SelectedUser>
        ))}
      </SelectedUsersWrapper>
      
      <div style={{ margin: 10, fontWeight: "bold", fontSize: 20 }}>
        Total Users: {totalUsers}
      </div>
      <SearchInput
        type="text"
        placeholder="Search by name, display picture or email"
        value={search}
        onChange={handleSearchChange}
      />
      {loading ? (
        <LoaderWrapper>
          <ClipLoader color="#007bff" loading={loading} size={150} />
        </LoaderWrapper>
      ) : (
        <>
          <TableWrapper>
            <Table>
              <TableHeader>
                <tr>
                  <th>Display Picture</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Subscribed</th>
                  <th>Verified</th>
                  <th>Trial</th>
                  <th>Last login At</th>
                  <th>Notify</th>
                </tr>
              </TableHeader>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.email}>
                    <td>
                      <DisplayPic src={user.display_pic} alt={user.display_name} />
                    </td>
                    <td>{user.display_name}</td>
                    <td>{user.email}</td>
                    <td>{user.subscribed ? 'Yes' : 'No'}</td>
                    <td>{user.verified ? 'Yes' : 'No'}</td>
                    <td>{user.trial ? 'Yes' : 'No'}</td>
                    <td>{new Date(user.updated_at).toLocaleString()}</td>
                    <td>
                      <ActionButton disabled={user.notification_token==''||user.notification_token==null} style={{backgroundColor:(user.notification_token==''||user.notification_token==null)?"red":selectedUsers.some(selectedUser => selectedUser.notification_token === user.notification_token) ? "green" : "#7f388b"}}  onClick={() => handleSelectUser(user)}>Notify</ActionButton>
                    </td>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
          <PaginationWrapper>
            <Pagination
              count={totalPages}
              page={currentPage + 1}
              onChange={handlePageChange}
              color="primary"
            />
          </PaginationWrapper>
        </>
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
      <MuiAlert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%' }}>
       {message}
      </MuiAlert>
    </Snackbar>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  min-height: 100vh;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 10px;
`;

const SubmitButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;

  &:hover {
    background-color: #218838;
  }
`;

const SelectedUsersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const SelectedUser = styled.div`
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background-color: #f8f9fa;

  th {
    padding: 12px;
    text-align: left;
    font-weight: bold;
    color: #333;
  }
`;


const TableBody = styled.tbody`
  tr:nth-child(even) {
    background-color: #f8f9fa;
  }
`;

const TableRow = styled.tr`
  td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }
`;

const DisplayPic = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const ActionButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
  token: state.auth.token
});

export default connect(mapStateToProps)(NotifyAdmin);
