import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

const BarChartComponent = ({ data }) => {
  const maxValue = Math.max(...data.map((item) => item.test_count));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="display_name" />
        <YAxis domain={[0, maxValue]} />
        <Tooltip />
        <Legend />
        <Bar dataKey="test_count" fill="#8884d8">
          <LabelList
            dataKey="percentile"
            position="inside"
            content={({ x, y, value }) => (
              <text
                style={{ marginLeft: 10, color: 'black', fontWeight: 'bold',}}
                x={x + (data.length > 5 ? 40 : 25)}
                y={y + 15}
                textAnchor="middle"
                fill="black"
                fontSize={14}
              >
                <tspan>{`${value==null ? 0:value}%ile`}</tspan>
              </text>
            )}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;