import React from 'react';
import styled, { keyframes } from 'styled-components';

const moveMarker = keyframes`
  10% { transform: translate(5%, 100%) rotate(2.5deg); }
  20% { transform: translate(20%, 0) rotate(-5deg); }
  30% { transform: translate(30%, 100%) rotate(2.5deg); }
  40% { transform: translate(40%, 0) rotate(-5deg); }
  50% { transform: translate(50%, 100%) rotate(2.5deg); }
  60% { transform: translate(60%, 0) rotate(-5deg); }
  70% { transform: translate(70%, 100%) rotate(2.5deg); }
  80% { transform: translate(80%, 0) rotate(-5deg); }
  90% { transform: translate(90%, 100%) rotate(2.5deg); }
  100% { transform: translate(100%, 0) rotate(-5deg); }
`;

const rainbowFill = keyframes`
  0% { background: var(--bg-01-a), var(--bg-02-a), var(--bg-03-a), var(--bg-04-a), var(--bg-05-a), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a), var(--bg-09-a), var(--bg-10-a); }
  10% { background: var(--bg-01-b), var(--bg-02-a), var(--bg-03-a), var(--bg-04-a), var(--bg-05-a), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a), var(--bg-09-a), var(--bg-10-a); }
  20% { background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-a), var(--bg-04-a), var(--bg-05-a), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a), var(--bg-09-a), var(--bg-10-a); }
  30% { background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-a), var(--bg-05-a), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a), var(--bg-09-a), var(--bg-10-a); }
  40% { background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b), var(--bg-05-a), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a), var(--bg-09-a), var(--bg-10-a); }
  50% { background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b), var(--bg-05-b), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a), var(--bg-09-a), var(--bg-10-a); }
  60% { background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b), var(--bg-05-b), var(--bg-06-b), var(--bg-07-a), var(--bg-08-a), var(--bg-09-a), var(--bg-10-a); }
  70% { background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b), var(--bg-05-b), var(--bg-06-b), var(--bg-07-b), var(--bg-08-a), var(--bg-09-a), var(--bg-10-a); }
  80% { background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b), var(--bg-05-b), var(--bg-06-b), var(--bg-07-b), var(--bg-08-b), var(--bg-09-a), var(--bg-10-a); }
  90% { background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b), var(--bg-05-b), var(--bg-06-b), var(--bg-07-b), var(--bg-08-b), var(--bg-09-b), var(--bg-10-a); }
  100% { background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b), var(--bg-05-b), var(--bg-06-b), var(--bg-07-b), var(--bg-08-b), var(--bg-09-b), var(--bg-10-b); }
`;

const LoaderWrapper = styled.div`
  --steps: 10;
  --saturation: 80%;
  --lightness: 60%;
  --hue-offset: 320;
  --duration: 5000ms;

  --color-01: hsl( calc( 360 / var(--steps) *  1 + var(--hue-offset)), var(--saturation), var(--lightness) );
  --color-02: hsl( calc( 360 / var(--steps) *  2 + var(--hue-offset)), var(--saturation), var(--lightness) );
  --color-03: hsl( calc( 360 / var(--steps) *  3 + var(--hue-offset)), var(--saturation), var(--lightness) );
  --color-04: hsl( calc( 360 / var(--steps) *  4 + var(--hue-offset)), var(--saturation), var(--lightness) );
  --color-05: hsl( calc( 360 / var(--steps) *  5 + var(--hue-offset)), var(--saturation), var(--lightness) );
  --color-06: hsl( calc( 360 / var(--steps) *  6 + var(--hue-offset)), var(--saturation), var(--lightness) );
  --color-07: hsl( calc( 360 / var(--steps) *  7 + var(--hue-offset)), var(--saturation), var(--lightness) );
  --color-08: hsl( calc( 360 / var(--steps) *  8 + var(--hue-offset)), var(--saturation), var(--lightness) );
  --color-09: hsl( calc( 360 / var(--steps) * 9 + var(--hue-offset)), var(--saturation), var(--lightness) );
  --color-10: hsl( calc( 360 / var(--steps) * 10 + var(--hue-offset)), var(--saturation), var(--lightness) );
  --color-11: hsl( calc( 360 / var(--steps) * 11 + var(--hue-offset)), var(--saturation), var(--lightness) );  
  --color-crayon: #7f388b;

  --bg-01-a: no-repeat left       0% top    / 11%   0% linear-gradient(to right, var(--color-01), var(--color-02));
  --bg-01-b: no-repeat left       0% top    / 11% 100% linear-gradient(to right, var(--color-01), var(--color-02));
  --bg-02-a: no-repeat left   11.11% bottom / 11%   0% linear-gradient(to right, var(--color-02), var(--color-03));
  --bg-02-b: no-repeat left   11.11% bottom / 11% 100% linear-gradient(to right, var(--color-02), var(--color-03));
  --bg-03-a: no-repeat left   22.22% top    / 11%   0% linear-gradient(to right, var(--color-03), var(--color-04));
  --bg-03-b: no-repeat left   22.22% top    / 11% 100% linear-gradient(to right, var(--color-03), var(--color-04));
  --bg-04-a: no-repeat left   33.33% bottom / 11%   0% linear-gradient(to right, var(--color-04), var(--color-05));
  --bg-04-b: no-repeat left   33.33% bottom / 11% 100% linear-gradient(to right, var(--color-04), var(--color-05)); 
  --bg-05-a: no-repeat left   44.44% top    / 11%   0% linear-gradient(to right, var(--color-05), var(--color-06));
  --bg-05-b: no-repeat left   44.44% top    / 11% 100% linear-gradient(to right, var(--color-05), var(--color-06)); 
  --bg-06-a: no-repeat left   55.55% bottom / 11%   0% linear-gradient(to right, var(--color-06), var(--color-07));
  --bg-06-b: no-repeat left   55.55% bottom / 11% 100% linear-gradient(to right, var(--color-06), var(--color-07)); 
  --bg-07-a: no-repeat left   66.66% top    / 11%   0% linear-gradient(to right, var(--color-07), var(--color-08));
  --bg-07-b: no-repeat left   66.66% top    / 11% 100% linear-gradient(to right, var(--color-07), var(--color-08));
  --bg-08-a: no-repeat left   77.77% bottom / 11%   0% linear-gradient(to right, var(--color-08), var(--color-09));
  --bg-08-b: no-repeat left   77.77% bottom / 11% 100% linear-gradient(to right, var(--color-08), var(--color-09));
  --bg-09-a: no-repeat left   88.88% top    / 11%   0% linear-gradient(to right, var(--color-09), var(--color-10));
  --bg-09-b: no-repeat left   88.88% top    / 11% 100% linear-gradient(to right, var(--color-09), var(--color-10));  
  --bg-10-a: no-repeat left   99.99% bottom / 11%   0% linear-gradient(to right, var(--color-10), var(--color-11));
  --bg-10-b: no-repeat left   99.99% bottom / 12% 100% linear-gradient(to right, var(--color-10), var(--color-11));   

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem 1rem 2rem 1rem;
  box-sizing: border-box;
  overflow: hidden;
  background:white;
`;

const Title = styled.h1`
  color: var(--color-crayon);
  font-size: 1.5rem;
  font-family: 'M PLUS Rounded 1c', sans-serif; 
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const RainbowMarkerLoader = styled.div`
  height: 4rem;
  width: 20rem;
  max-width: 100%;
  border: .5rem solid var(--color-crayon);
  border-radius: .5rem;
  animation: ${rainbowFill} var(--duration) ease-in-out infinite alternate;
  box-sizing: border-box;
  position: relative;
  margin: 1rem;
  background:
    var(--bg-01-a),
    var(--bg-02-a),
    var(--bg-03-a),
    var(--bg-04-a),
    var(--bg-05-a),
    var(--bg-06-a),
    var(--bg-07-a),
    var(--bg-08-a),
    var(--bg-09-a),
    var(--bg-10-a);

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: left center;
    border-radius: .5rem;
    box-sizing: border-box;  
    margin-top: -7.5%;
    margin-left: -2.5%;
    animation: ${moveMarker} var(--duration) ease-in-out infinite alternate;    
    background:
      no-repeat left   0% top    / 3rem  50% linear-gradient(to bottom right, transparent, transparent 40%, var(--color-crayon) 40%),
      no-repeat left   0% bottom / 3rem  50% linear-gradient(to top right, transparent, transparent 40%, var(--color-crayon) 40%),
      no-repeat left 3rem bottom / 100% 100% linear-gradient(var(--color-crayon), var(--color-crayon));
  }
`;

const RainbowLoader = () => {
  return (
    <LoaderWrapper>
      <Title>VERBOACE LOADING</Title>
      <RainbowMarkerLoader />
    </LoaderWrapper>
  );
};

export default RainbowLoader;