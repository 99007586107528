import React from 'react';

const recognizedCompanies = [
  { name: 'Startup-India', logoUrl: 'https://storage.googleapis.com/assets_verboace/startup-india-4.jpg', link: 'https://www.forbes.com' },
  { name: 'Google Startup Program', logoUrl: 'https://storage.googleapis.com/assets_verboace/1_Q82e5dcp7_llvYN6P4CtvQ.png', link: 'https://mashable.com' },
  { name: 'Microsoft', logoUrl: ' https://storage.googleapis.com/assets_verboace/images.jpeg', link: 'https://mashable.com' },
  { name: 'ASU + GSV', logoUrl: 'https://storage.googleapis.com/assets_verboace/609c5537f13f617280ab7143_SEO-Card-2021-2.png', link: 'https://mashable.com' },
    

   
];

const RecognizedBy = () => {
  return (
    <div style={styles.container}>
     <div style={styles.logoContainer}>
        {recognizedCompanies.map((company, index) => (
          <div
            key={index}
            style={styles.logoLink}
          >
            <img src={company.logoUrl} alt={`${company.name} logo`} style={styles.logo} />
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    margin: '20px 0',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color:"#7f388b",
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  logoLink: {
    margin: '0 15px',
  },
  logo: {
    maxWidth: '300px',
    height: '200px',
    borderRadius:20,
    marginHorizontal:20,
    margin:10
  },
};

export default RecognizedBy;
