import React, { useEffect, useRef, useState } from 'react';
import { CardColumns, Col, Row } from 'react-bootstrap';
import useMobileDetect from 'use-mobile-detect-hook';
import './App.css';
import Box from './box';
import higherImage from './images/higher.jpg';
import upsc from './images/upsc.jpg';
import school from './images/school.jpg';
import robot from './images/robot.jpg';
import { Diversity1 } from '@mui/icons-material';
import { Bounce, Fade,  Zoom } from 'react-reveal';
import Flip from 'react-reveal/Flip';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import verboace from './images/verboace.png';
import RocketComponent from './Comp/rocket-launch';
import { LoadingSpinner } from 'video-react';
import { TypeAnimation } from 'react-type-animation';
import { Slide } from "react-awesome-reveal";
import ReactPlayer from 'react-player';

function HomeNew({scrollToRef,home,product,story,contactus}) {
  const [isNavSticky, setIsNavSticky] = useState(false);
  const detectMobile = useMobileDetect();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);  
  const [showThumbnail, setShowThumbnail] = useState(true);
  const playerRef = useRef(null);


  const handleImageLoad = () => {
    setImageLoaded(true);
  };


  const handlePlay = () => {
    setIsPlaying(true);
    setShowThumbnail(false);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (showThumbnail) {
      handlePlay();
    } else {
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    // Load main background image
    const bgImage = new Image();
    bgImage.onload = () => {
      setImageLoaded(true);
    };
    bgImage.src = 'https://storage.googleapis.com/assets_verboace/bg-hd.png';

    // Simulate loading delay (you can remove this in actual implementation)

  }, []);

    const renderPlatformSpecificContent = () => {
    if (detectMobile.isIos()) {
      return (
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
        <div style={{
      width: '95%',
      maxWidth: '600px',
      margin: '0 auto',
      position: 'relative',
      borderRadius: '15px',
      overflow: 'hidden',
    height:300
    }}>
      <ReactPlayer
        ref={playerRef}
        url='https://storage.googleapis.com/assets_verboace/Product_Final.mp4'
        playing={isPlaying}
        controls={!showThumbnail}
        width="100%"
        height="auto"
        style={{ aspectRatio: '16 / 9' }}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handlePause}
      />
      {!isPlaying && (
        <div 
          onClick={handlePlayPause}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url('https://storage.googleapis.com/assets_verboace/VERBOACE.COM.png')`,
            backgroundSize:"cover",
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <div style={{
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            backgroundColor: 'rgba(0,0,0,0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <div style={{
              width: 0,
              height: 0,
              borderTop: '15px solid transparent',
              borderBottom: '15px solid transparent',
              borderLeft: '25px solid white',
              marginLeft: '5px',
            }} />
          </div>
        </div>
      )}
    </div>
        <a href="itms-apps://apps.apple.com/in/app/verboace/id6504613427" className="app-store-button" style={{marginTop:20}}>
        <img src='https://storage.googleapis.com/assets_verboace/ios.png' style={{height:100,width:"auto"}}></img>
        </a>
       </div>
      );
    } else if (detectMobile.isAndroid()) {
      return (
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderBottomColor:"#fff",borderWidth:0}}>
        <div style={{
      width: '95%',
      maxWidth: '600px',
      margin: '0 auto',
      position: 'relative',
      borderRadius: '15px',
      overflow: 'hidden',
    height:300
    }}>
      <ReactPlayer
        ref={playerRef}
        url='https://storage.googleapis.com/assets_verboace/Product_Final.mp4'
        playing={isPlaying}
        controls={!showThumbnail}
        width="100%"
        height="auto"
        style={{ aspectRatio: '16 / 9' }}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handlePause}
      />
      {!isPlaying && (
        <div 
          onClick={handlePlayPause}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url('https://storage.googleapis.com/assets_verboace/VERBOACE.COM.png')`,
            backgroundSize:"cover",
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <div style={{
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            backgroundColor: 'rgba(0,0,0,0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <div style={{
              width: 0,
              height: 0,
              borderTop: '15px solid transparent',
              borderBottom: '15px solid transparent',
              borderLeft: '25px solid white',
              marginLeft: '5px',
            }} />
          </div>
        </div>
      )}
    </div> <a href="https://play.google.com/store/apps/details?id=com.rahuldevelops.verboaceapp" className="app-store-button" style={{marginTop:60}}>
        <img src='https://storage.googleapis.com/assets_verboace/play.png' style={{height:80,width:"100%",marginBottom:40}}></img>
        </a>
       </div>
      );
    } else {
      return (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          width: '100vw',
          height: '100vh',
          perspective: '1000px',
          position: 'relative',
          overflow: 'hidden',
          marginTop:-150,
          marginBottom:-120
        }}>
        <div style={{ 
  position: 'absolute',
  left: isPlaying ? '50%' : '15%',
  top: '50%',
  transform: isPlaying 
    ? 'translate(-50%, -50%) translateZ(100px)' 
    : 'translate(0, -50%)',
  transition: 'all 1s ease',
  zIndex: isPlaying ? 1000 : 1,
  width: isPlaying ? '60%' : '600px',
  height: '500px', // Keep this fixed for both states
}}>
   <div style={{ position: 'relative', width: '100%', height: '100%', borderRadius: '15px', overflow: 'hidden' }}>
    <div style={{ borderRadius: '15px', overflow: 'hidden', width: '100%', height: '100%' }}>
      <ReactPlayer
        ref={playerRef}
        url='https://storage.googleapis.com/assets_verboace/Product_Final.mp4'
        playing={isPlaying}
        controls={!showThumbnail}
        width="100%"
        height="100%"
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handlePause}
      />
    </div>
          {!isPlaying && (
            <div 
              onClick={handlePlayPause}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: '15px',
                width: '100%',
                height: '100%',
                backgroundImage: `url('https://storage.googleapis.com/assets_verboace/VERBOACE.COM.png')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <div style={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                backgroundColor: 'rgba(0,0,0,0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <div style={{
                  width: 0,
                  height: 0,
                  borderTop: '15px solid transparent',
                  borderBottom: '15px solid transparent',
                  borderLeft: '25px solid white',
                  marginLeft: '5px',
                }} />
              </div>
            </div>
          )}
        </div>
      </div>
    
          <div style={{ 
            position: 'absolute',
            right: '5%',
            display: "flex", 
            flexDirection: "row", 
            justifyContent: "center", 
            alignItems: "center",
            opacity: isPlaying ? 0 : 1,
            visibility: isPlaying ? 'hidden' : 'visible',
            transition: 'opacity 0.5s ease, visibility 0.5s ease'
          }}>
            <Slide direction={"right"}>
              <img src={"https://storage.googleapis.com/assets_verboace/first.png"} alt="Placeholder" className="responsive-image" style={{height: 500, width: "500px"}} />
            </Slide>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginLeft:-230,zIndex:1000}}>
              <a href="https://apps.apple.com/in/app/verboace/id6504613427" className="app-store-button" style={{ marginTop: 25 }}>
                <img src='https://storage.googleapis.com/assets_verboace/ios.png' style={{ height: 100, width: "auto" }} alt="App Store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.rahuldevelops.verboaceapp" className="play-store-button" style={{ marginTop: 20 }}>
                <img src='https://storage.googleapis.com/assets_verboace/play.png' style={{ height: 62, width: "auto", marginTop: 5 }} alt="Play Store" />
              </a>
            </div>
          </div>
        </div>
      );
    }
  };

  // Function to handle navbar position
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsNavSticky(true);
    } else {
      setIsNavSticky(false);
    }
  };

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Attach scroll event listener
  window.addEventListener('scroll', handleScroll);

  return (
    <div className="Landing" style={{backgroundColor:"white"}}>
   <div style={{backgroundColor:"white"}} className={`menu-icon ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
      </div>
      { detectMobile.isMobile() ?  <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"auto",margin:0,flexDirection:"column"}}>
      <img src={verboace} onLoad={handleImageLoad} style={{width:"auto",height:120}}></img>
        </div>:null}
      <nav style={{backgroundColor:"white"}} className={`navbar ${isNavOpen ? 'open' : ''}`} >
        <div className="nav-links" style={{marginLeft:10, fontWeight:"bold",color:"#7f388b",alignItems:"center",justifyContent:"center",textAlign:"center"}} >
        <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}} onClick={()=>{scrollToRef(home)}}>Home</Link>
          <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}} onClick={()=>{scrollToRef(product)}}>Product</Link>
           <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}} onClick={()=>{scrollToRef(contactus)}}>Contact Us</Link>
           <Link to="/terms-conditions" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}}  onClick={()=>{}}>Terms</Link>
            </div>
        { detectMobile.isMobile() ? null : <div className="nav-logo" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>  <img src={verboace} style={{width:"auto",height:120}}></img>
      </div>}
        <div className="nav-links" style={{justifyContent:"center",alignItems:"center",fontWeight:"bold",color:"#7f388b",alignItems:"center",justifyContent:"center",textAlign:"center"}}>
      
        <Link to="/privacy-policy" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}} onClick={()=>{}}>Privacy-Policy</Link>
        {detectMobile.isMobile() ?  <Link to="/refund-policy" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}}  onClick={()=>{}}>Refund-Policy</Link>
        : <Link to="/login-student" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}}  onClick={()=>{}}>Student Login</Link>}
        </div>
      </nav>
     
      <div style={{marginTop:10+"%",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}} >
      <div
  style={{ 
    fontSize: detectMobile.isMobile() ? '2em' : '2em', 
    display: 'inline-block', 
    marginBottom: 20, 
    fontWeight: "bold",
    padding: 16,
    marginTop:detectMobile.isMobile()?-50: -20,
    color:"#7f388b",
    textAlign: "center", 
    fontFamily:"Impact"
  }}
>
  Say No to AI Chatbots & Expensive coaching institutes - Say Hi to India's First AI-Agent for EdTech to excel CAT exam
</div>
      </div>
      <div className="content" style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",height:"auto",marginTop:detectMobile.isMobile()?0:0+"%"}}>
        {!detectMobile.isMobile() && <div style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,
  color: 'black',
  fontSize: detectMobile.isMobile()?'1m': '2em',
  lineHeight:'71px'
}}>
  
</div>}






{!detectMobile.isMobile() ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "auto", marginTop: 20, flexDirection: "column" }}>
          {renderPlatformSpecificContent()}
        </div>
      ) : null}
<div style={{margin:0}}>
{detectMobile.isMobile() ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "auto", margin: 0, flexDirection: "column" }}>
          {renderPlatformSpecificContent()}
        </div>
      ) : null}
</div>


{/* 
<div style={{margin:20}}>



{/* <RocketComponent></RocketComponent> */}
{/* </div> */}
{/* <p style={{backgroundImage: "linear-gradient(to right, #c556c3, #7f388b)",color:"white",fontWeight:"bold",padding:10,paddingRight:20,paddingLeft:20, borderRadius:10,marginTop:20,fontSize:detectMobile.isMobile()?15: 25,marginBottom:0}}>Invite only - Launching on Aug 1st</p>
<div style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontStyle: 'normal',
  fontWeight: 700,
  marginTop:10,
  paddingLeft:20,
  paddingRight:20,
  color:"#7f388b"
}}>
</div> */}
<div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row", width:100+"vw"}}>
{/* <div style={{marginTop:20, width:100+"vw",display:"flex",flexDirection:detectMobile.isMobile()? "column":"row",justifyContent:"space-evenly",alignItems:"center"}}>
      <div  style={{margin:20}}>
      <Bounce>
      <Box icon={robot} text="Super Assistant" />
      </Bounce>
      </div>
      <div  style={{margin:20}}>
      <Bounce>
      <Box icon={higherImage} text="CAT/GRE/GMAT" />
      </Bounce>
      </div>
      <div  style={{margin:20}}>
      <Bounce>
      <Box icon={upsc} text="UPSC/SSC/RBI" />
      </Bounce>
      </div>
      <div  style={{margin:20}}>
      <Bounce>
      <Box icon={school} text="NCERT/JEE" />
      </Bounce>
      </div>
    </div> */}

   
    </div>
       
       
      </div>
    </div>
  );
}

export default HomeNew;



// import React, { useEffect, useState } from 'react';
// import { CardColumns, Col, Row } from 'react-bootstrap';
// import useMobileDetect from 'use-mobile-detect-hook';
// import './App.css';
// import Box from './box';
// import higherImage from './images/higher.jpg';
// import upsc from './images/upsc.jpg';
// import school from './images/school.jpg';
// import robot from './images/robot.jpg';
// import { Diversity1 } from '@mui/icons-material';
// import { Bounce, Fade,  Zoom } from 'react-reveal';
// import Slide from 'react-reveal/Slide';
// import Flip from 'react-reveal/Flip';
// import { Link } from 'react-router-dom/cjs/react-router-dom';
// import verboace from './images/verboace.png';
// import RocketComponent from './Comp/rocket-launch';
// import { LoadingSpinner } from 'video-react';


// function HomeNew({scrollToRef,home,product,story,contactus}) {
//   const [isNavSticky, setIsNavSticky] = useState(false);
//   const detectMobile = useMobileDetect();
//   const [imageLoaded, setImageLoaded] = useState(false);

//   const handleImageLoad = () => {
//     setImageLoaded(true);
//   };



//   useEffect(() => {
//     // Load main background image
//     const bgImage = new Image();
//     bgImage.onload = () => {
//       setImageLoaded(true);
//     };
//     bgImage.src = 'https://storage.googleapis.com/assets_verboace/bg-hd.png';

//     // Simulate loading delay (you can remove this in actual implementation)

//   }, []);

//   // Function to handle navbar position
//   const handleScroll = () => {
//     if (window.scrollY > 0) {
//       setIsNavSticky(true);
//     } else {
//       setIsNavSticky(false);
//     }
//   };

//   const [isNavOpen, setIsNavOpen] = useState(false);

//   const toggleNav = () => {
//     setIsNavOpen(!isNavOpen);
//   };

//   // Attach scroll event listener
//   window.addEventListener('scroll', handleScroll);

//   return (
//     <div className="Landing" style={{backgroundColor:"white"}}>
//    <div style={{backgroundColor:"white"}} className={`menu-icon ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
//         <div className="menu-line"></div>
//         <div className="menu-line"></div>
//         <div className="menu-line"></div>
//       </div>
     
//       <nav style={{backgroundColor:"white"}} className={`navbar ${isNavOpen ? 'open' : ''}`} >
//         <div className="nav-links" style={{marginLeft:10, fontWeight:"bold",color:"#7f388b",alignItems:"center",justifyContent:"center",textAlign:"center"}} >
//         <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}} onClick={()=>{scrollToRef(home)}}>Home</Link>
//           <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}} onClick={()=>{scrollToRef(product)}}>Product</Link>
//            <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}} onClick={()=>{scrollToRef(contactus)}}>Contact Us</Link>
//            <Link to="/terms-conditions" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}}  onClick={()=>{}}>Terms</Link>
//             </div>
//         { detectMobile.isMobile() ? null : <div className="nav-logo" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>  <img src={verboace} style={{width:"auto",height:120}}></img>
//       </div>}
//         <div className="nav-links" style={{justifyContent:"center",alignItems:"center",fontWeight:"bold",color:"#7f388b",alignItems:"center",justifyContent:"center",textAlign:"center"}}>
      
//         <Link to="/privacy-policy" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}} onClick={()=>{}}>Privacy-Policy</Link>
//         <Link to="/refund-policy" className="nav-button" style={{fontWeight:"bold",color:"#7f388b"}}  onClick={()=>{}}>Refund-Policy</Link>
//         </div>
//       </nav>
//       <div style={{marginTop:10+"%",display:"flex",justifyContent:"center",alignItems:"center"}} >
     
//       </div>
//       <div className="content" style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",height:"auto",marginTop:detectMobile.isMobile()?0:0+"%"}}>
//         {!detectMobile.isMobile() && <div style={{
//   fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
//   fontWeight: 900,
//   color: 'black',
//   fontSize: '2em',
//   lineHeight:'50px'
// }}>
//   <Fade>
//   Join the most Sophisticated & Innovative Tech-Enabled platform
//   </Fade>
// </div>}
// {!detectMobile.isMobile() && <div style={{
//   fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
//   fontWeight: 900,
//   color: 'black',
//   fontSize: '2em',
//   lineHeight: '50px'
// }}>
//   <Fade>
//   that assures 99%ile with 100% cashback promise
//   </Fade>
// </div>}

// {detectMobile.isMobile() ? <div style={{
//   fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
//   fontWeight: 900,
//   color: 'black',
//   fontSize: detectMobile.isMobile ? '1.8em':'3.5em',
//   padding:20
// }}>
//   Join the most Sophisticated & Innovative Tech-Enabled platform that assures 99%ile with 100% cashback promise
// </div>:null}
// {!detectMobile.isMobile() ? (
//         <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "auto", marginTop: 20, flexDirection: "column" }}>
//           {renderPlatformSpecificContent()}
//         </div>
//       ) : null}
// <div style={{margin:0}}>
// {detectMobile.isMobile() ? (
//         <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "auto", margin: 0, flexDirection: "column" }}>
//           {renderPlatformSpecificContent()}
//         </div>
//       ) : null}
// <RocketComponent></RocketComponent>
// </div>
// <p style={{backgroundImage: "linear-gradient(to right, #c556c3, #7f388b)",color:"white",fontWeight:"bold",padding:10,paddingRight:15,paddingLeft:15, borderRadius:5,marginTop:20,fontSize:detectMobile.isMobile()?12: 18,marginBottom:0}}>GET ON THE ROCKET TO YOUR DREAM B-SCHOOL</p>
// <div style={{
//   fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
//   fontStyle: 'normal',
//   fontWeight: 700,
//   marginTop:10,
//   paddingLeft:20,
//   paddingRight:20,
//   color:"#7f388b"
// }}>
// </div>

// <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row", width:100+"vw"}}>
// {/* <div style={{marginTop:20, width:100+"vw",display:"flex",flexDirection:detectMobile.isMobile()? "column":"row",justifyContent:"space-evenly",alignItems:"center"}}>
//       <div  style={{margin:20}}>
//       <Bounce>
//       <Box icon={robot} text="Super Assistant" />
//       </Bounce>
//       </div>
//       <div  style={{margin:20}}>
//       <Bounce>
//       <Box icon={higherImage} text="CAT/GRE/GMAT" />
//       </Bounce>
//       </div>
//       <div  style={{margin:20}}>
//       <Bounce>
//       <Box icon={upsc} text="UPSC/SSC/RBI" />
//       </Bounce>
//       </div>
//       <div  style={{margin:20}}>
//       <Bounce>
//       <Box icon={school} text="NCERT/JEE" />
//       </Bounce>
//       </div>
//     </div> */}

   
//     </div>
       
       
//       </div>
//     </div>
//   );
// }

// export default HomeNew;