import React, { Component, useCallback, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Footer from './Comp/footer';
import Header1 from './Comp/Header1';
import Wordsstories from './Comp/Wordsstories';
import "bootstrap/dist/css/bootstrap.css"
import "./index.css"
import pic from './images/pic.png'
import pc from './images/pc.png'
import logo from './images/philomath_logo.png'
import chrome from './images/chrom.png'
import Main from './Comp/main';
import Mediaplayer from './Comp/Videoplayer';
import Feature1 from './Comp/feature1';
import Feature2 from './Comp/feature2';
import Feature3 from './Comp/feature3';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Cards from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import 'material-icons/iconfont/material-icons.css';
import splash4 from './images/ai.gif'
import correct from './images/correct.gif'
import splash from './images/androidintegration.gif'
import { v4 as uuidv4 } from "uuid";
import splash2 from './images/quiz.gif'
import Card from "./Card";
import { Status } from "status-modal/dist";
import Carousel from "./carousel.jsx";
import { Dots } from 'loading-animations-react';
import splash3 from './images/notifications.gif'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { Wave } from 'react-animated-text';
import { TypeAnimation } from 'react-type-animation';
import { LightSpeed } from 'react-reveal';
import { Slide } from "react-awesome-reveal";
import Carroussel from './carousel.jsx';
import TextAnimation from './textAnimation';
import HomeNew from './Home';
import ArticleSection from './articles';
import ContactForm from './contactform';
import Test from './secret';
import PrivacyPolicy from './Comp/privacy-policy';
import RefundPolicy from './Comp/refund-policy';
import TermsAndConditions from './Comp/terms-conditions';
import TeamComponent from './Comp/ourTeam.jsx';
import LoadingComponent from './Comp/loadingComponent.jsx';
import RCTest from './rctest.jsx';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin,useGoogleLogin } from '@react-oauth/google';
import { Button } from '@mui/material';
import axios from 'axios'
import LoginModal from './Comp/loginModal.jsx';
import { QuizProvider } from './quizContext.js';
import Dashboard from './Comp/dashboard.jsx';
import CombinedChartComponent from './Comp/analytics.jsx';
import Testimonials from './Comp/Testimonial.jsx';
import Testimonial from './Comp/Testimonial.jsx';
import UserTable from './usersAdmin.js';
import NotifyAdmin from './notifyAdmin.js';
import ScatterPlot from './Comp/clusterGraph.jsx';
import RcClassroom from './Comp/rcClassroom.jsx';
import RecognizedBy from './Comp/recognised.jsx';
import SolutionRender from './Comp/solutionRender.jsx';
// import jwt_decode from 'jwt-decode';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDU1ewh0vouYZdDvoU4f_xr-KB-Lwc_gL8",
  authDomain: "philomath-web.firebaseapp.com",
  projectId: "philomath-web",
  storageBucket: "philomath-web.appspot.com",
  messagingSenderId: "750741503708",
  appId: "1:750741503708:web:b27d2abe1a2beebb957e19",
  measurementId: "G-YP22P8VPHR"
};
// Initialize Firebase


function Root(){
  const app = initializeApp(firebaseConfig);
  const containerRef = useRef(null);
// Export firestore database
// It will be imported into your react app whenever it is needed
 const db = getFirestore(app);

 const home = useRef(null);
 const product = useRef(null);
 const story = useRef(null);
 const contactus = useRef(null);

 const scrollToRef = (ref) => {
   if (ref.current) {
     ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
   }
 };
 const analytics = getAnalytics(app);
  const [loading,setLoading] = useState(true)
  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [showAnimations,setShowAnimations]=useState(false)
  const [showText,setShowText]= useState(false)
  const [success,setSuccess] = useState(false)
  const [EmailWrong,setEmailWrong] = useState(false)
  const [first,setFirst]=useState(false)
  const [second,setSecond]=useState(false)
  const [third,setThird]=useState(false)
  const [fourth,setFourth]=useState(false)
  const [fifth,setFifth]=useState(false)
  const [sixth,setSixth]=useState(false)
  const [seventh,setSeventh]=useState(false)
  const [eight,seteight]=useState(false)
  const [ninth,setninth]=useState(false)
  const [tenth,settenth]=useState(false)
  const [eleventh,seteleventh]=useState(false)
  const [firstTime,setFirstTime] = useState(true)
  const [showAnimation,setShowAnimation] = useState(false)
  const [animationCompleted,setAnimationCompleted] = useState(true)
  const [user,setUser] = useState(null)
  const [showLoginModal, setShowLoginModal] = useState(true);

  const handleLoginModalClose = () => setShowLoginModal(false);
  const handleLoginModalShow = () => setShowLoginModal(true);


  const testimonialsData = [
    {
      quote: "“These treats are real tail-waggers”",
      author: "My pug Sir Biscuit is obsessed with the Salmon Snackers! He does a little dance when he hears the Barkin' Baxter's truck coming. - Lily R.",
      styleClass: 'a'
    },
    {
      quote: '"A bona-fido game-changer"',
      author: 'Baxters are at the top of the food chain! Maximus is a picky eater but he loves these treats. - Albert Z.',
      styleClass: 'b'
    },
    {
      quote: '"Treat time has never been so dog-gone amazing"',
      author: 'Our little Mabel gives them two paws up and a whole lot of slobbery kisses. - Jennifer T.',
      styleClass: 'c'
    }
  ];

  useEffect(() => {
    // Load main background image
    const bgImage = new Image();
    bgImage.onload = () => {
      setFourth(true);
    };
    bgImage.src = 'https://storage.googleapis.com/assets_verboace/bg-hd.png';

    const bgImage1 = new Image();
    bgImage1.onload = () => {
      setFirst(true);
    };
    bgImage1.src = 'https://storage.googleapis.com/assets_verboace/first.png';

    const bgImage2 = new Image();
    bgImage2.onload = () => {
      setSecond(true);
    };
    bgImage2.src = 'https://storage.googleapis.com/assets_verboace/second.png';


    const bgImage3 = new Image();
    bgImage3.onload = () => {
      setThird(true);
    };
    bgImage3.src = 'https://storage.googleapis.com/assets_verboace/third.png';


    const bgImage4 = new Image();
    bgImage4.onload = () => {
      seteight(true);
    };
    bgImage4.src = 'https://storage.googleapis.com/assets_verboace/percentile_improvement.png';


    const bgImage5 = new Image();
    bgImage5.onload = () => {
      setninth(true);
    };
    bgImage5.src = 'https://storage.googleapis.com/assets_verboace/Simulator%20Screenshot%20-%20iphone%208%20plus%20-%202024-06-28%20at%2022.12.25.png';


    const bgImage6 = new Image();
    bgImage6.onload = () => {
      settenth(true);
    };
    bgImage6.src = 'https://storage.googleapis.com/assets_verboace/Simulator%20Screenshot%20-%20iPhone%2015%20Pro%20Max%20-%202024-06-27%20at%2001.18.05.png';


    const bgImage7 = new Image();
    bgImage7.onload = () => {
      seteleventh(true);
    };
    bgImage7.src = 'https://storage.googleapis.com/assets_verboace/VERBOACE.COM.png';
    
    // Simulate loading delay (you can remove this in actual implementation)

  }, []);

  
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

 


  const [y, setY] = useState(window.scrollY);

const handleNavigation = useCallback(
  e => {
  
    const window = e.currentTarget;
    if (y > window.scrollY ) {
      if(seventh && !showAnimation){
        setAnimationCompleted(true)
      }
      console.log(showAnimation,animationCompleted)
    if(showAnimation || !animationCompleted){
      const focused = window.document.querySelector('.interactive')
      focused.scrollIntoView({ behavior: 'auto' })
      }
    } else if (y < window.scrollY) {
      
      if(width<600){
        console.log(y)
        if(y>1000){
          if(firstTime){
          const focused = window.document.querySelector('.interactive')
          focused.scrollIntoView({ behavior: 'auto' })
          containerRef.current.focus();
          setShowAnimation(true)
          setAnimationCompleted(false)
          setFirstTime(false)
        }
        }
      }
      else{
        if(firstTime){
      const focused = window.document.querySelector('.interactive')
      focused.scrollIntoView({ behavior: 'auto' })
      containerRef.current.focus();
      setShowAnimation(true)
      setFirstTime(false)
      }
    }
    if(firstTime){
    if(showAnimation || !animationCompleted){
      const focused = window.document.querySelector('.interactive')
      focused.scrollIntoView({ behavior: 'auto' })
      }
    }
  }
    setY(window.scrollY);
  }, [y]
);

//  const googleLogin = useGoogleOneTapLogin({
//   onSuccess: credentialResponse => {
//     console.log(credentialResponse);
//   },
//   onError: () => {
//     console.log('Login Failed');
//   },
// });



  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const [email,setEmail] = useState('')
  const addEmail = async (e) => {
        e.preventDefault();  
        if(validateEmail(email.trim())){
        try {
            const docRef = await addDoc(collection(db, "emails"), {
              email: email.trim(),    
            });
            console.log("Document written with ID: ", docRef.id);
            setEmail('')
            setSuccess(true)
          } catch (e) {
            console.error("Error adding document: ", e);
          }
        }else{
          setEmailWrong(true)
        }
    }
 

const [load1,setload1] = useState(true)
const [load2,setload2] = useState(true)
const [load3,setload3] = useState(true)
const [load4,setload4] = useState(true)
const [load5,setload5] = useState(false)

const ref1 = useRef()
const ref2 = useRef()
const ref3 = useRef()


const onLoad1 = ()=>{
  setload1(true)
}
const onLoad2 = ()=>{
  setload2(true)
}
const onLoad3 = ()=>{
  setload3(true)
}
useEffect(() => {
  if(load1 && load2 && load3){
    setShowAnimations(true)
    setTimeout(()=>{
      setShowText(true)
    },500)
  }
}, [load1,load2,load3]);

useEffect(()=>{
console.log(width)
},[width])



// useEffect(
//   async() => {
//       if (user) {
//         try {
//           const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user}`, {
//             method: 'GET',
//             headers: {
//               Authorization: `Bearer ${user}`,
//               Accept: 'application/json'
//             }
//           });
      
//           if (!response.ok) {
//             throw new Error('Network response was not ok');
//           }
      
//           const data = await response.json();
//           console.log(data);
//         } catch (error) {
//           console.error('Fetch error:', error);
//         }
//       }
//   },
//   [ user ]
// );




  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card imagen={pic} title={'Android app'} content={'Select the word from any app in your smartphone and click on philomath in the options, It is that easy to search and bookmark the word without having to open the app explicitly.'} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={pc} title={'Web app'} content={'Using Machine learning technology, we will suggest you best version of sentences or paragraphes you wrote through our powerful AI models, which can detect spelling mistakes, grammatical mistakes and even acronyms. All this from anywhere from your phone. '} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={chrome} title={'Chrome app'} content={'Machine learning model that is built by regularly crawling through famous english magazines and old exam question papers will give you probability of importance for the word and related word graphs'} />
      )
    }
  ];

  const [ profile, setProfile ] = useState([]);

  // const login = useGoogleLogin({
  //     onSuccess: (codeResponse) => setUser(codeResponse),
  //     onError: (error) => console.log('Login Failed:', error)
  // });

  // useEffect(
  //     () => {
  //         if (user) {
  //             axios
  //                 .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
  //                     headers: {
  //                         Authorization: `Bearer ${user.access_token}`,
  //                         Accept: 'application/json'
  //                     }
  //                 })
  //                 .then((res) => {
  //                     setProfile(res.data);
  //                 })
  //                 .catch((err) => console.log(err));
  //         }
  //     },
  //     [ user ]
  // );

  const LoginBackend = async(email,token)=>{
    const logininfo = await axios.post(
      'https://api.verboace.com/v1/auth/loginweb',
      {
        email: 'your_email@example.com',
        token: 'your_token_here'
      }
    );
    console.log(logininfo)
  }

  const login = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      const userInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${access_token}` } },
      );
  
      LoginBackend(userInfo.data.email,access_token);
    },
  });

return(
<div style={{backgroundColor:"white"}}>
<GoogleOAuthProvider clientId="346911359027-8egt0g77ajs8h9s3j0067tb9nuo7e1b9.apps.googleusercontent.com">
<QuizProvider>
{(first && second && third && fourth && eight && ninth && tenth && eleventh) ?
<Router>
      <div style={{backgroundColor:"white"}}>
        <Switch>
        {/* <Route path="/analytics">
            <CombinedChartComponent />
          </Route> */}
        <Route path="/testing">
            {/* Render the Test component when the URL is /secret */}
            <RCTest />
          </Route>
          <Route path="/classroom">
            {/* Render the Test component when the URL is /secret */}
            <RcClassroom />
          </Route>
          <Route path="/admin">
            {/* Render the Test component when the URL is /secret */}
            <UserTable />
          </Route>
          <Route path="/solution">
            {/* Render the Test component when the URL is /secret */}
            <SolutionRender />
          </Route>
          <Route path="/notify">
            {/* Render the Test component when the URL is /secret */}
            <NotifyAdmin />
          </Route>
          <Route path="/home">
            {/* Render the Test component when the URL is /secret */}
            <Dashboard />
          </Route>
          {/* Route for the secret path */}
          <Route path="/super-secret">
            {/* Render the Test component when the URL is /secret */}
            <Test />
          </Route>
            <Route path="/terms-conditions">
            {/* Render the Test component when the URL is /secret */}
            <TermsAndConditions />
          </Route>
          <Route path="/privacy-policy">
            {/* Render the Test component when the URL is /secret */}
            <PrivacyPolicy />
          </Route>
          <Route  path="/login-student">
          <LoginModal show={showLoginModal} handleClose={handleLoginModalClose} />
          </Route>

          <Route path="/refund-policy">
            {/* Render the Test component when the URL is /secret */}
            <RefundPolicy />
          </Route>
          <Route path="/">
          <Helmet>
        <title>India's First AI-Agent for EdTech.</title>
        <meta name="description" content="India's First AI Agent for EdTech." />
        <meta name="keywords" content="Home, verboace.com, AI, Agent, Edtech, CAT, MBA " />
      </Helmet>
{load1 && load2 && load3 && load4 ? <div ref={home} style={{backgroundColor:"white",overflowAnchor:"none",display:'flex',flexDirection:"column",background:"black"}}>


    {/* <a href="/">Home</a> | <a href="/register">Add Bill</a> */}

<HomeNew scrollToRef={scrollToRef} home={home} product={product} story={story} contactus={contactus} />


    {/* <Footer></Footer> */}
<div className='main' id='main' style={{display:"flex",height:"auto",justifyContent:"space-evenly",alignItems:"center",width:100+"%",background:"white",padding:0}}>
  
 <div style={{backgroundColor:"white",height:100+"%", display:"flex",justifyContent:"flex-start",alignItems:"center",flexDirection:"column",padding:0,width:100+"%"}}>
 {/* <div style={{marginTop:50}}>
<ScatterPlot></ScatterPlot>
</div> */}
 <div ref={product} style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,

  color: 'rgb(36, 34, 62)',
  fontSize: '36px',
  lineHeight: '71px',
  marginTop:width>600?50:0+"%",
  background:"white",
  color:"#7f388b",
  textAlign:"center",
  padding:16
}}>
  Product Features
</div>
{/* <Mediaplayer></Mediaplayer> */}

<div className={width>768 ? "responsive-component-even": "responsive-component-odd"}>

<div className={width>768 ?"image-container-even" : "image-container-odd"}>
      <Slide direction='left'>
        <img src={"https://storage.googleapis.com/assets_verboace/q.png"} alt="Placeholder" className="responsive-image" />
        </Slide>
      </div>
     
      <div className="content-container">
      <Slide direction='right'>
        <h2 className="title">{"First State of the art AI Agent for Edtech"}</h2>
        <p className="description"> <ul style={{ textAlign: "left", paddingLeft: 0 }}>
  <li>An AI agent who is constantly watching your tests and constantly analysing your preparation to give advise after each test.</li>
  <li>This system is designed to not only help you find your strengths and weakness but also hidden strengths and weakness.</li>
  <li>A mentor that no human mentor or teacher can match.</li>
  <li>Runs analysis overnight and gives you daily report on where do you stand, where to focus.</li>
  <li>Each student will have their own mentor and their own platform adopted continously to student's pace of learning.</li>
 </ul>
 </p>
       </Slide>
      </div>
      
    </div>


<div className="responsive-component-odd">

<div className="image-container-odd">
<Slide direction='right'>
  <img src={"https://storage.googleapis.com/assets_verboace/second.png"} alt="Placeholder" className="responsive-image-odd" />
  </Slide>
</div>


<div className="content-container">
<Slide direction='left'>
  <h2 className="title">{"Unique Social Media Approach"}</h2>
  <p className="description"> <ul style={{ textAlign: "left", paddingLeft: 0 }}>
  <li>First of its kind in Edtech space to bring social media fun into learning while keeping experience intact.</li>
  <li>Make Study buddies and network organically among aspirants before joining B-school.</li>
  <li>Follow, React and Get Notified about your buddy's progress.</li>
  <li>Challenge and Compare your results and performance with buddies.</li>
  <li>Connect your linkedin and meet future class mates and friends.</li>
  <li>Take Challenges on toppers and get rewards.</li>
  <li>Stay motivated throughout with a best network for MBA Aspirants.</li>
</ul></p>
 </Slide>
</div>

</div>



<div className={width>768 ? "responsive-component-even": "responsive-component-odd"}>

      <div className={width>768 ?"image-container-even" : "image-container-odd"}>
      <Slide direction='left'>
        <img src={"https://storage.googleapis.com/assets_verboace/vvdd.png"} alt="Placeholder" className="responsive-image" />
        </Slide>
      </div>
     
      <div className="content-container">
      <Slide direction='right'>
        <h2 className="title">{"Very Innovative & First of its kind - VVDD"}</h2>
        <p className="description"><ul style={{ textAlign: "left", paddingLeft: 0 }}>
        <li>Instant consultation with CAT toppers and share your Preparation data to get proper advise.</li>
        <li>VVDD is a way to request help directly from toppers who have scored 99.9+%ile.</li>
        <li>Toppers have access to your entire performance and analytics data in the app.</li>
        <li>You will get a voice message from toppers advising on where do you lack and how to approach.</li>
        <li>You will get solution to the questions you have not answered correctly.</li>
        <li>Limited tokens, unlimited potential to excel at CAT exam through VVDD.</li>
       </ul></p>
       </Slide>
      </div>
      
    </div>


    <div className="responsive-component-odd">

      <div className="image-container-odd">
      <Slide direction='right'>
        <img src={"https://storage.googleapis.com/assets_verboace/third.png"} alt="Placeholder" className="responsive-image-odd" />
        </Slide>
      </div>
     
      <div className="content-container">
      <Slide direction='left'>
        <h2 className="title">{"Verboace Coach with Percentile Predictor"}</h2>
        <p className="description"> <ul style={{ textAlign: "left", paddingLeft: 0 }}>
    <li>Understand where do you stand and how far are you from your goals.</li>
    <li>Not only you, the tests also get adopted to your weaknesses and strengths based on predicted percentile</li>
    <li>Entire platform is built to push you towards 99%ile.</li>
    <li>Individual categories, areas and trends analysis based on machine learning.</li>
    <li>Peer Benchmarking: Understand your ranking among peers.</li>
    <li>Insightful Analysis: Get clear insights into your potential performance.</li>
  </ul></p>
       </Slide>
      </div>
      
    </div>
   
    <div className={width>768 ? "responsive-component-even": "responsive-component-odd"}>

<div className={width>768 ?"image-container-even" : "image-container-odd"}>
      <Slide direction='left'>
        <img src={"https://storage.googleapis.com/assets_verboace/first.png"} alt="Placeholder" className="responsive-image" />
        </Slide>
      </div>
     
      <div className="content-container">
      <Slide direction='right'>
        <h2 className="title">{"200 CAT RCs Curated by 99.9+ percentilers"}</h2>
        <p className="description"> <ul style={{ textAlign: "left", paddingLeft: 0 }}>
  <li>Exclusive Collection: Access a curated set of 150 Reading Comprehension passages.</li>
  <li>Meticulously Crafted: Carefully selected by 99.9+ Percentile CAT Scorers.</li>
  <li>Tailored for CAT Aspirants: Designed to meet the needs of CAT exam preparation.</li>
  <li>Highest Quality: Ensure top-notch content for effective study.</li>
  <li>Relevant Content: Focus on topics relevant to the CAT exam.</li>
  <li>Comprehensive Preparation: Cover a wide range of passages from philosophy to economics to enhance skills.</li>
 </ul>
 </p>
       </Slide>
      </div>
      
    </div>

    {/* <div style={{fontSize:width>600?35:20,marginTop:35,marginBottom:10, fontWeight:"bold",color:"#7f388b",padding:16,textAlign:"center",fontFamily:"sans-serif"}}>State Of The Art Features That You Will Find No Where Else</div>

    <div style={{display:"flex",flexDirection:width>600?"row":"column",justifyContent:"space-between",alignItems:"center",width:width>600?95+"%":100+"%",marginBottom:5+"%",marginTop:width>600?5:1+"%", background:"white"}}>
<Zoom>
<Card  title={'Silent Guardian - A watchful mentor'} content={"Verboace Coach keeps an eye on your performance in each test and commments what went wrong, where to improve and how much did this test affect your expected percentile."} imagen={'https://storage.googleapis.com/assets_verboace/percentile_improvement.png'}  >
       <img src='https://storage.googleapis.com/assets_verboace/percentile_improvement.png' style={{height:400,width:"auto", borderRadius:5}}></img>
      </Card>
      </Zoom>
    
      <Zoom>
<Card  title={'Dr Strange to rescue - See your future'} content={"State of the art predictor algorithm that is trained on previous years papers and equipped with complex data to predict very accurate 99%ile prediction path for students."} imagen={'https://storage.googleapis.com/assets_verboace/Simulator%20Screenshot%20-%20iPhone%2015%20Pro%20Max%20-%202024-06-27%20at%2001.17.54.png'}  >
       <img src='https://storage.googleapis.com/assets_verboace/Simulator%20Screenshot%20-%20iphone%208%20plus%20-%202024-06-28%20at%2022.12.25.png' style={{height:400,width:"auto", borderRadius:5}}></img>
      </Card>
      </Zoom>

      <Zoom>
<Card  title={'Everyone deserves best guide - Learn like a pro'} content={"Get into classroom to see each test broken down into paragraphs to explain each paragraphs along with a toppers' tip. Red highlighter to highlight what to focus on while reading."} imagen={'https://storage.googleapis.com/assets_verboace/Simulator%20Screenshot%20-%20iPhone%2015%20Pro%20Max%20-%202024-06-27%20at%2001.18.05.png'}  >
       <img src='https://storage.googleapis.com/assets_verboace/percentile_improvement.png' style={{height:400,width:"auto", borderRadius:5}}></img>
      </Card>
      </Zoom>

      <Zoom>
<Card  title={'Do not just compete - Compare with toppers directly'} content={"Verboace Coach keeps an eye on your performance in each test and commments what went wrong, where to improve and how much did this test affect your expected percentile."} imagen={'https://storage.googleapis.com/assets_verboace/Simulator%20Screenshot%20-%20iPhone%2015%20Pro%20Max%20-%202024-06-27%20at%2001.17.19.png'}  >
       <img src='https://storage.googleapis.com/assets_verboace/percentile_improvement.png' style={{height:400,width:"auto", borderRadius:5}}></img>
      </Card>
      </Zoom>

</div> */}

<div ref={contactus} style={{marginBottom:5+"%",width:100+"vw"}}>
<div ref={product} style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,

  color: 'rgb(36, 34, 62)',
  fontSize: '28px',
  lineHeight: '71px',
  marginTop:width>600?30:5+"%",
  background:"white",
  color:"#7f388b",
  textAlign:"center",
  padding:16
}}>
  Proudly Recognized by
</div>
<RecognizedBy />
</div>

<div ref={product} style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,

  color: 'rgb(36, 34, 62)',
  fontSize: '30px',
  lineHeight: '71px',
  marginTop:width>600?30:5+"%",
  background:"white",
  color:"#7f388b",
  textAlign:"center",
  padding:16
}}>
  Our Early Users Are Saying
</div>

<div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      background: '#fff',
      flexDirection:width>760?"row":"column"
    }}>
    
      <Testimonial
        title="Game changer, Never seen anything like this before"
        text="I’m excited to be among the early users of Verboace! The app’s innovative features, like the percentile predictor & Verboace Coach are truly groundbreaking in the edtech space. It’s amazing to see such advanced tools designed to help CAT aspirants achieve top percentiles."
        source="- Ayushman Bharadwaj"
      />
       <Testimonial
        title="Loved the Product and Efforts of the Team"
        text="This app is great and lot of rigour is seen in building this great innovative platform to help the students ace their VARC section in exams to get into top B-schools Kudos to the team. This is real tech innovation that I am happy to pay for."
        source="- Pranav Simha"
      />
      <Testimonial
        title="Meticulously thought system and designed really well"
        text="Verboace has been meticulously thought of and designed to help prospective CAT aspirants ace their VARC section. It has features that help you track your progress personally as well as get guidance from previous CAT VARC 99.9+%ilers."
        source="Neha Rangam"
      />
    </div>

<div ref={story} style={{marginBottom:5+"%"}}>
<TeamComponent />
</div>

<div ref={story} style={{marginBottom:5+"%"}}>
<ArticleSection />
</div>
<div ref={contactus} style={{marginBottom:5+"%",width:100+"vw"}}>
<ContactForm />
</div>
<div style={{width:100+"vw"}}>
<Footer></Footer>
</div>
</div>
 </div>
</div>
:
<div style={{background:"linear-gradient(to bottom, #001F3F, #1E90FF)",display:"flex",width:100+"%",height:100+"vh",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
<div class="loader"></div>
<div style={{color:"white",fontSize:25,fontWeight:"bold",margin:20}}>Loading please wait...</div>
</div>}
</Route>
</Switch>
</div>
</Router>:<LoadingComponent></LoadingComponent>}
</QuizProvider>
</GoogleOAuthProvider>
</div>
);

}
export default Root;





{/* <div ref={product} style={{display:"flex",flexDirection:width>600?"row":"column",justifyContent:"space-between",alignItems:"flex-start",width:width>600?90+"%":100+"%",marginBottom:5+"%",marginTop:width>600?5:0+"%", background:"white"}}>
<Bounce>


<Cards  variant="outlined" style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center", margin: 20, height: width > 600 ? 450 : 600 , background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
<CardContent style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
  <div style={{ 
    width: width > 600 ? 300 : "80%", 
    padding: 10, 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    flexDirection: "column" 
  }}>
    <div style={{ 
      textAlign: "center", 
      color: "black", 
      fontSize: 20, 
      fontWeight: "bold", 
      fontFamily: "Bolden" 
    }}>
      150 CAT RCs Curated by 99.9+ percentilers
    </div>
    <div style={{ 
      fontSize: 15, 
      color: "black", 
      textAlign: "center", 
      marginTop: "4%" 
    }}>
      <ul style={{ textAlign: "left", paddingLeft: 0 }}>
        <li>Exclusive Collection: Access a curated set of 150 Reading Comprehension passages.</li>
        <li>Meticulously Crafted: Carefully selected by 99.9+ Percentile CAT Scorers.</li>
        <li>Tailored for CAT Aspirants: Designed to meet the needs of CAT exam preparation.</li>
        <li>Highest Quality: Ensure top-notch content for effective study.</li>
        <li>Relevant Content: Focus on topics relevant to the CAT exam.</li>
        <li>Comprehensive Preparation: Cover a wide range of passages from philosophy to economics to enhance skills.</li>
       </ul>
    </div>
  </div>
</CardContent>

      </Cards>
      </Bounce>
      <Bounce>
   <Cards variant="outlined" style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center",margin:20,height:width > 600 ? 450 : 600,background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
   <CardContent style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
  <div style={{ 
    width: width > 600 ? 300 : "80%", 
    padding: 10, 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    flexDirection: "column" 
  }}>
    <div style={{ 
      textAlign: "center", 
      color: "black", 
      fontSize: 20, 
      fontWeight: "bold", 
      fontFamily: "Bolden" 
    }}>
      Innovative and first of its kind social platform
    </div>
    <div style={{ 
      fontSize: 15, 
      color: "black", 
      textAlign: "center", 
      marginTop: "4%" 
    }}>
      <ul style={{ textAlign: "left", paddingLeft: 0 }}>
      <li>Auto-Post Scores: Seamlessly share your test results.</li>
    <li>Expert Guidance: Tag founders for personalized advice.</li>
    <li>Engage & Discuss: Participate in vibrant discussions.</li>
    <li>Connect with Peers: Meet fellow aspirants and share insights.</li>
    <li>Stay Motivated: Collaborate to strengthen and overcome challenges.</li>
    <li>Boost Your Prep: Elevate your preparation like never before!</li>
      </ul>
    </div>
  </div>
</CardContent>
   </Cards>
   </Bounce>
   <Bounce>
   <Cards variant="outlined" style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center",margin:20,height:width>600?450:600,background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
      <CardContent style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center"}}>
   <div style={{width:width>600?300:80+"%",padding:10,display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
    <div style={{textAlign:"center",color:"black",fontSize:20,fontWeight:"bold",fontFamily:"Bolden"}}>AI Predicted Percentile with your preparation data</div>
    <div style={{fontSize:15,color:"black",textAlign:"center",marginTop:4+"%"}}>
      
    <ul style={{ textAlign: "left", paddingLeft: 0 }}>
    <li>Machine Learning Power: Advanced algorithms analyze your data.</li>
    <li>Comprehensive Evaluation: Assess study hours, topic proficiency, and practice test scores.</li>
    <li>Personalized Predictions: Receive a tailored percentile estimate.</li>
    <li>Peer Benchmarking: Understand your ranking among peers.</li>
    <li>Insightful Analysis: Get clear insights into your potential performance.</li>
  </ul>
   </div>
   </div>
   </CardContent>
   </Cards></Bounce>

</div>


<div ref={product} style={{display:"flex",flexDirection:width>600?"row":"column",justifyContent:"space-between",alignItems:"flex-start",width:width>600?90+"%":100+"%",marginBottom:5+"%",marginTop:width>600?5:0+"%", background:"white"}}>
<Bounce>
<Cards  variant="outlined" style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center", margin: 20, height: width > 600 ? 450 : 600 , background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
        <CardContent style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center", }}>
          <div style={{ width: width > 600 ? 300 : 80 + "%", padding: 10, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <div style={{ textAlign: "center", color: "black", fontSize: 25, fontWeight: "bold", fontFamily: "Bolden" }}>Personal mentoring and tips by 99 percentilers</div>
            <div style={{ fontSize: 15, color: "black", textAlign: "center", marginTop: 4 + "%" }}>
            <ul style={{ textAlign: "left", paddingLeft: 0 }}>
    <li>Top Scorer Strategies: Learn from the best in the field.</li>
    <li>Detailed Guidance: Approach each Reading Comprehension passage and paragraph with confidence.</li>
    <li>Enhanced Comprehension: Sharpen your skills and pinpoint key details.</li>
    <li>Effective Time Management: Master the art of pacing through tips.</li>
    <li>Remote Learning Advantage: Gain valuable insights without in-person teaching.</li>
  </ul>
            </div>
          </div>
        </CardContent>
      </Cards>
      </Bounce>
      <Bounce>
   <Cards variant="outlined" style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center",margin:20,height:width>600?450:600,background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
      <CardContent style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center"}}>
   <div style={{width:width>600?300:80+"%",padding:10,display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
   <div style={{textAlign:"center",color:"black",fontSize:25,fontWeight:"bold",fontFamily:"Bolden"}}>All India level competitions with rewards</div>
    <div style={{fontSize:15,color:"black",textAlign:"center",marginTop:4+"%"}}>
    <ul style={{ textAlign: "left", paddingLeft: 0 }}>
    <li>Nationwide Challenges: Compete in All India Level Competitions.</li>
    <li>Simulated Exam Conditions: Tackle challenging questions under real exam scenarios.</li>
    <li>Earn Rewards: Top performers receive exciting prizes.</li>
    <li>Competitive Edge: Foster a spirit of healthy competition.</li>
    <li>Consistent Effort: Encourage continuous improvement and dedication.</li>
  </ul>
       </div>
   </div>
   </CardContent>
   </Cards>
   </Bounce>
   <Bounce>
   <Cards variant="outlined" style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center",margin:20,height:width>600?450:600,background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
      <CardContent style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center"}}>
   <div style={{width:width>600?300:80+"%",padding:10,display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
    <div style={{textAlign:"center",color:"black",fontSize:25,fontWeight:"bold",fontFamily:"Bolden"}}>Weekly Reports on progress and personal consultation</div>
    <div style={{fontSize:15,color:"black",textAlign:"center",marginTop:4+"%"}}>
    <ul style={{ textAlign: "left", paddingLeft: 0 }}>
    <li>In-Depth Analysis: Get detailed reports on strengths and areas for improvement.</li>
    <li>Expert Consultations: Receive tailored advice from experienced professionals.</li>
    <li>Effective Strategies: Develop customized study plans.</li>
    <li>Continuous Motivation: Stay driven and focused on your goals.</li>
    <li>Achieve Excellence: Maximize your potential with personalized guidance.</li>
  </ul>
       </div>
   </div>
   </CardContent>
   </Cards></Bounce>

</div> */}


// import React, { Component, useCallback, useEffect, useRef, useState } from 'react';
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
// import Footer from './Comp/footer';
// import Header1 from './Comp/Header1';
// import Wordsstories from './Comp/Wordsstories';
// import "bootstrap/dist/css/bootstrap.css"
// import "./index.css"
// import pic from './images/pic.png'
// import pc from './images/pc.png'
// import logo from './images/philomath_logo.png'
// import chrome from './images/chrom.png'
// import Main from './Comp/main';
// import Mediaplayer from './Comp/Videoplayer';
// import Feature1 from './Comp/feature1';
// import Feature2 from './Comp/feature2';
// import Feature3 from './Comp/feature3';
// import Navbar from 'react-bootstrap/Navbar'
// import Nav from 'react-bootstrap/Nav'
// import Container from 'react-bootstrap/Container'
// import Cards from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import 'material-icons/iconfont/material-icons.css';
// import splash4 from './images/ai.gif'
// import correct from './images/correct.gif'
// import splash from './images/androidintegration.gif'
// import { v4 as uuidv4 } from "uuid";
// import splash2 from './images/quiz.gif'
// import Card from "./Card";
// import { Status } from "status-modal/dist";
// import Carousel from "./carousel.jsx";
// import { Dots } from 'loading-animations-react';
// import splash3 from './images/notifications.gif'
// import Fade from 'react-reveal/Fade';
// import Zoom from 'react-reveal/Zoom';
// import Bounce from 'react-reveal/Bounce';
// import { initializeApp } from "firebase/app";
// import { addDoc, collection, getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// import { Wave } from 'react-animated-text';
// import { TypeAnimation } from 'react-type-animation';
// import { LightSpeed } from 'react-reveal';
// import { Slide } from "react-awesome-reveal";
// import Carroussel from './carousel.jsx';
// import TextAnimation from './textAnimation';
// import HomeNew from './Home';
// import ArticleSection from './articles';
// import ContactForm from './contactform';
// import Test from './secret';
// import PrivacyPolicy from './Comp/privacy-policy';
// import RefundPolicy from './Comp/refund-policy';
// import TermsAndConditions from './Comp/terms-conditions';
// import TeamComponent from './Comp/ourTeam.jsx';
// import LoadingComponent from './Comp/loadingComponent.jsx';
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDU1ewh0vouYZdDvoU4f_xr-KB-Lwc_gL8",
//   authDomain: "philomath-web.firebaseapp.com",
//   projectId: "philomath-web",
//   storageBucket: "philomath-web.appspot.com",
//   messagingSenderId: "750741503708",
//   appId: "1:750741503708:web:b27d2abe1a2beebb957e19",
//   measurementId: "G-YP22P8VPHR"
// };
// // Initialize Firebase


// function Root(){
//   const app = initializeApp(firebaseConfig);
//   const containerRef = useRef(null);
// // Export firestore database
// // It will be imported into your react app whenever it is needed
//  const db = getFirestore(app);

//  const home = useRef(null);
//  const product = useRef(null);
//  const story = useRef(null);
//  const contactus = useRef(null);

//  const scrollToRef = (ref) => {
//    if (ref.current) {
//      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
//    }
//  };
//  const analytics = getAnalytics(app);
//   const [loading,setLoading] = useState(true)
//   const [width, setWidth]   = useState(window.innerWidth);
//   const [height, setHeight] = useState(window.innerHeight);
//   const [showAnimations,setShowAnimations]=useState(false)
//   const [showText,setShowText]= useState(false)
//   const [success,setSuccess] = useState(false)
//   const [EmailWrong,setEmailWrong] = useState(false)
//   const [first,setFirst]=useState(false)
//   const [second,setSecond]=useState(false)
//   const [third,setThird]=useState(false)
//   const [fourth,setFourth]=useState(false)
//   const [fifth,setFifth]=useState(false)
//   const [sixth,setSixth]=useState(false)
//   const [sevent,setSevent] = useState(false)
//   const [eight,seteight] = useState(false)
//   const [seventh,setSeventh]=useState(false)
//   const [firstTime,setFirstTime] = useState(true)
//   const [showAnimation,setShowAnimation] = useState(false)
//   const [animationCompleted,setAnimationCompleted] = useState(true)

//   // <img src={"https://storage.googleapis.com/assets_verboace/iphone.png"}style={{ width: 'auto', height: 350 }} alt="Web" />
//   //         <a href="https://apps.apple.com" className="app-store-button" style={{marginTop:20}}>
//   //         <img src='https://storage.googleapis.com/assets_verboace/ios.png' style={{height:100,width:"auto"}}></img>
//   //         </a>
//   //        </div>
//   //        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
//   //         <img src={"https://storage.googleapis.com/assets_verboace/android.png"}style={{ width: 'auto', height: 350 }} alt="Web" />
//   //         <a href="https://apps.apple.com" className="app-store-button" style={{marginTop:20}}>
//   //         <img src='https://storage.googleapis.com/assets_verboace/play.png' style={{height:62,width:"auto",marginTop:18}}></img>
         

//   useEffect(() => {
//     // Load main background image
//     const bgImage = new Image();
//     bgImage.onload = () => {
//       setFourth(true);
//     };
//     bgImage.src = 'https://storage.googleapis.com/assets_verboace/bg-hd.png';

//     const bgImage1 = new Image();
//     bgImage1.onload = () => {
//       setFirst(true);
//     };
//     bgImage1.src = 'https://storage.googleapis.com/assets_verboace/first.png';

//     const bgImage2 = new Image();
//     bgImage2.onload = () => {
//       setSecond(true);
//     };
//     bgImage2.src = 'https://storage.googleapis.com/assets_verboace/second.png';


//     const bgImage3 = new Image();
//     bgImage3.onload = () => {
//       setThird(true);
//     };
//     bgImage3.src = 'https://storage.googleapis.com/assets_verboace/third.png';

//     const bgImage4 = new Image();
//     bgImage4.onload = () => {
//       setFourth(true);
//     };
//     bgImage4.src = 'https://storage.googleapis.com/assets_verboace/iphone.png';

//     const bgImage5 = new Image();
//     bgImage5.onload = () => {
//       setFifth(true);
//     };
//     bgImage5.src = 'https://storage.googleapis.com/assets_verboace/android.png';

//     const bgImage6 = new Image();
//     bgImage6.onload = () => {
//       setSixth(true);
//     };
//     bgImage6.src = 'https://storage.googleapis.com/assets_verboace/ios.png';

//     const bgImage7 = new Image();
//     bgImage7.onload = () => {
//       setSevent(true);
//     };
//     bgImage7.src = 'https://storage.googleapis.com/assets_verboace/play.png';

//     // Simulate loading delay (you can remove this in actual implementation)

//   }, []);

  
//   const updateDimensions = () => {
//       setWidth(window.innerWidth);
//       setHeight(window.innerHeight);
//   }
//   const validateEmail = (email) => {
//     return String(email)
//       .toLowerCase()
//       .match(
//         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//       );
//   };

 


//   const [y, setY] = useState(window.scrollY);

// const handleNavigation = useCallback(
//   e => {
  
//     const window = e.currentTarget;
//     if (y > window.scrollY ) {
//       if(seventh && !showAnimation){
//         setAnimationCompleted(true)
//       }
//       console.log(showAnimation,animationCompleted)
//     if(showAnimation || !animationCompleted){
//       const focused = window.document.querySelector('.interactive')
//       focused.scrollIntoView({ behavior: 'auto' })
//       }
//     } else if (y < window.scrollY) {
      
//       if(width<600){
//         console.log(y)
//         if(y>1000){
//           if(firstTime){
//           const focused = window.document.querySelector('.interactive')
//           focused.scrollIntoView({ behavior: 'auto' })
//           containerRef.current.focus();
//           setShowAnimation(true)
//           setAnimationCompleted(false)
//           setFirstTime(false)
//         }
//         }
//       }
//       else{
//         if(firstTime){
//       const focused = window.document.querySelector('.interactive')
//       focused.scrollIntoView({ behavior: 'auto' })
//       containerRef.current.focus();
//       setShowAnimation(true)
//       setFirstTime(false)
//       }
//     }
//     if(firstTime){
//     if(showAnimation || !animationCompleted){
//       const focused = window.document.querySelector('.interactive')
//       focused.scrollIntoView({ behavior: 'auto' })
//       }
//     }
//   }
//     setY(window.scrollY);
//   }, [y]
// );



// useEffect(() => {

//   // setY(window.scrollY);
//   // window.addEventListener("scroll", handleNavigation);

//   // return () => {
//   //   window.removeEventListener("scroll", handleNavigation);
//   // };
// }, [handleNavigation]);

//   useEffect(() => {
//       window.addEventListener("resize", updateDimensions);
//       return () => window.removeEventListener("resize", updateDimensions);
//   }, []);
//   const [email,setEmail] = useState('')
//   const addEmail = async (e) => {
//         e.preventDefault();  
//         if(validateEmail(email.trim())){
//         try {
//             const docRef = await addDoc(collection(db, "emails"), {
//               email: email.trim(),    
//             });
//             console.log("Document written with ID: ", docRef.id);
//             setEmail('')
//             setSuccess(true)
//           } catch (e) {
//             console.error("Error adding document: ", e);
//           }
//         }else{
//           setEmailWrong(true)
//         }
//     }
 

// const [load1,setload1] = useState(true)
// const [load2,setload2] = useState(true)
// const [load3,setload3] = useState(true)
// const [load4,setload4] = useState(true)
// const [load5,setload5] = useState(false)

// const ref1 = useRef()
// const ref2 = useRef()
// const ref3 = useRef()


// const onLoad1 = ()=>{
//   setload1(true)
// }
// const onLoad2 = ()=>{
//   setload2(true)
// }
// const onLoad3 = ()=>{
//   setload3(true)
// }
// useEffect(() => {
//   if(load1 && load2 && load3){
//     setShowAnimations(true)
//     setTimeout(()=>{
//       setShowText(true)
//     },500)
//   }
// }, [load1,load2,load3]);

// useEffect(()=>{
// console.log(width)
// },[width])


//   let cards = [
//     {
//       key: uuidv4(),
//       content: (
//         <Card imagen={pic} title={'Android app'} content={'Select the word from any app in your smartphone and click on philomath in the options, It is that easy to search and bookmark the word without having to open the app explicitly.'} />
//       )
//     },
//     {
//       key: uuidv4(),
//       content: (
//         <Card imagen={pc} title={'Web app'} content={'Using Machine learning technology, we will suggest you best version of sentences or paragraphes you wrote through our powerful AI models, which can detect spelling mistakes, grammatical mistakes and even acronyms. All this from anywhere from your phone. '} />
//       )
//     },
//     {
//       key: uuidv4(),
//       content: (
//         <Card imagen={chrome} title={'Chrome app'} content={'Machine learning model that is built by regularly crawling through famous english magazines and old exam question papers will give you probability of importance for the word and related word graphs'} />
//       )
//     }
//   ];
// return(
// <div style={{backgroundColor:"white"}}>
// {(first && second && third && fourth && fifth && sixth && sevent) ?
// <Router>
//       <div style={{backgroundColor:"white"}}>
//         <Switch>
//           {/* Route for the secret path */}
//           <Route path="/super-secret">
//             {/* Render the Test component when the URL is /secret */}
//             <Test />
//           </Route>
//             <Route path="/terms-conditions">
//             {/* Render the Test component when the URL is /secret */}
//             <TermsAndConditions />
//           </Route>
//           <Route path="/privacy-policy">
//             {/* Render the Test component when the URL is /secret */}
//             <PrivacyPolicy />
//           </Route>
//           <Route path="/refund-policy">
//             {/* Render the Test component when the URL is /secret */}
//             <RefundPolicy />
//           </Route>
//           <Route path="/">
// {load1 && load2 && load3 && load4 ? <div ref={home} style={{backgroundColor:"white",overflowAnchor:"none",display:'flex',flexDirection:"column",background:"black"}}>


//     {/* <a href="/">Home</a> | <a href="/register">Add Bill</a> */}

// <HomeNew scrollToRef={scrollToRef} home={home} product={product} story={story} contactus={contactus} />
//     {/* <Footer></Footer> */}
// <div className='main' id='main' style={{display:"flex",height:"auto",justifyContent:"space-evenly",alignItems:"center",width:100+"%",background:"white",padding:0}}>
//  <div style={{backgroundColor:"white",height:100+"%", display:"flex",justifyContent:"flex-start",alignItems:"center",flexDirection:"column",padding:0,width:100+"%"}}>
  
//  <div ref={product} style={{
//   fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
//   fontWeight: 900,

//   color: 'rgb(36, 34, 62)',
//   fontSize: '36px',
//   lineHeight: '71px',
//   marginTop:width>600?50:5+"%",
//   background:"white",
//   color:"#7f388b",
//   textAlign:"center",
//   padding:16
// }}>
//   Product Features
// </div>
// {/* <Mediaplayer></Mediaplayer> */}
// <div className="responsive-component-odd">

// <div className="image-container-odd">
// <Slide direction='right'>
//   <img src={"https://storage.googleapis.com/assets_verboace/second.png"} alt="Placeholder" className="responsive-image-odd" />
//   </Slide>
// </div>

// <div className="content-container">
// <Slide direction='left'>
//   <h2 className="title">{"Unique Social Media Approach"}</h2>
//   <p className="description"> <ul style={{ textAlign: "left", paddingLeft: 0 }}>
//   <li>First of its kind in Edtech space to bring social media fun into learning while keeping experience intact.</li>
// <li>Auto-Post Scores: Seamlessly share your test results.</li>
// <li>Expert Guidance: Tag founders for personalized advice.</li>
// <li>Engage & Discuss: Participate in vibrant discussions.</li>
// <li>Connect with Peers: Meet fellow aspirants and share insights.</li>
// <li>Stay Motivated: Collaborate to strengthen and overcome challenges.</li>
// <li>Boost Your Prep: Elevate your preparation like never before!</li>
// <li>Challenge, Follow and Learn from Each other</li>
// <li>Directly compare your results, responses with each toppers.</li>
// </ul></p>
//  </Slide>
// </div>

// </div>

// <div className={width>768 ? "responsive-component-even": "responsive-component-odd"}>

//       <div className={width>768 ?"image-container-even" : "image-container-odd"}>
//       <Slide direction='left'>
//         <img src={"https://storage.googleapis.com/assets_verboace/vvdd.png"} alt="Placeholder" className="responsive-image" />
//         </Slide>
//       </div>
     
//       <div className="content-container">
//       <Slide direction='right'>
//         <h2 className="title">{"Very Innovative & First of its kind - VVDD"}</h2>
//         <p className="description"><ul style={{ textAlign: "left", paddingLeft: 0 }}>
//         <li>Verboace Voice Diagnostic Dispatch.</li>
//         <li>VVDD is a way to request help directly from toppers who have scored 99.9+%ile.</li>
//         <li>Toppers have access to your entire performance and analytics data in the app.</li>
//         <li>You will get a voice message from toppers advising on where do you lack and how to approach.</li>
//         <li>You will get solution to the questions you have not answered correctly.</li>
//         <li>Limited tokens, unlimited potential to excel at CAT exam through VVDD.</li>
//        </ul></p>
//        </Slide>
//       </div>
      
//     </div>


//     <div className="responsive-component-odd">

//       <div className="image-container-odd">
//       <Slide direction='right'>
//         <img src={"https://storage.googleapis.com/assets_verboace/third.png"} alt="Placeholder" className="responsive-image-odd" />
//         </Slide>
//       </div>
     
//       <div className="content-container">
//       <Slide direction='left'>
//         <h2 className="title">{"Verboace Coach with Percentile Predictor"}</h2>
//         <p className="description"> <ul style={{ textAlign: "left", paddingLeft: 0 }}>
//     <li>Machine Learning Power: Advanced algorithms analyze your data.</li>
//     <li>Verboace Coach: A personal mentor that can replace your tuition teachers.</li>
//     <li>Comprehensive Evaluation: Assess study hours, topic proficiency, and practice test scores.</li>
//     <li>Personalized Predictions: Receive a tailored percentile estimate.</li>
//     <li>Peer Benchmarking: Understand your ranking among peers.</li>
//     <li>Insightful Analysis: Get clear insights into your potential performance.</li>
//   </ul></p>
//        </Slide>
//       </div>
      
//     </div>
   
//     <div className={width>768 ? "responsive-component-even": "responsive-component-odd"}>

// <div className={width>768 ?"image-container-even" : "image-container-odd"}>
//       <Slide direction='left'>
//         <img src={"https://storage.googleapis.com/assets_verboace/first.png"} alt="Placeholder" className="responsive-image" />
//         </Slide>
//       </div>
     
//       <div className="content-container">
//       <Slide direction='right'>
//         <h2 className="title">{"200 CAT RCs Curated by 99.9+ percentilers"}</h2>
//         <p className="description"> <ul style={{ textAlign: "left", paddingLeft: 0 }}>
//   <li>Exclusive Collection: Access a curated set of 150 Reading Comprehension passages.</li>
//   <li>Meticulously Crafted: Carefully selected by 99.9+ Percentile CAT Scorers.</li>
//   <li>Tailored for CAT Aspirants: Designed to meet the needs of CAT exam preparation.</li>
//   <li>Highest Quality: Ensure top-notch content for effective study.</li>
//   <li>Relevant Content: Focus on topics relevant to the CAT exam.</li>
//   <li>Comprehensive Preparation: Cover a wide range of passages from philosophy to economics to enhance skills.</li>
//  </ul>
//  </p>
//        </Slide>
//       </div>
      
//     </div>



//     <div style={{display:"flex",flexDirection:width>600?"row":"column",justifyContent:"space-between",alignItems:"flex-start",width:width>600?90+"%":100+"%",marginBottom:5+"%",marginTop:width>600?5:0+"%", background:"white"}}>
// <Zoom>
// <Cards  variant="outlined" style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center", margin: 20, height: width > 600 ? 450 : 600 , background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
//         <CardContent style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center", }}>
//           <div style={{ width: width > 600 ? 300 : 80 + "%", padding: 10, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
//             <div style={{ textAlign: "center", color: "black", fontSize: 25, fontWeight: "bold", fontFamily: "Bolden" }}>Personal mentoring and tips by 99 percentilers</div>
//             <div style={{ fontSize: 15, color: "black", textAlign: "center", marginTop: 4 + "%" }}>
//             <ul style={{ textAlign: "left", paddingLeft: 0 }}>
//     <li>Top Scorer Strategies: Learn from the best in the field.</li>
//     <li>Detailed Guidance: Approach each Reading Comprehension passage and paragraph with confidence.</li>
//     <li>Enhanced Comprehension: Sharpen your skills and pinpoint key details.</li>
//     <li>Effective Time Management: Master the art of pacing through tips.</li>
//     <li>Remote Learning Advantage: Gain valuable insights without in-person teaching.</li>
//   </ul>
//             </div>
//           </div>
//         </CardContent>
//       </Cards>
//       </Zoom>
//       <Zoom>
//    <Cards variant="outlined" style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center",margin:20,height:width>600?450:600,background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
//       <CardContent style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center"}}>
//    <div style={{width:width>600?300:80+"%",padding:10,display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
//    <div style={{textAlign:"center",color:"black",fontSize:25,fontWeight:"bold",fontFamily:"Bolden"}}>All India level competitions with rewards</div>
//     <div style={{fontSize:15,color:"black",textAlign:"center",marginTop:4+"%"}}>
//     <ul style={{ textAlign: "left", paddingLeft: 0 }}>
//     <li>Nationwide Challenges: Compete in All India Level Competitions.</li>
//     <li>Simulated Exam Conditions: Tackle challenging questions under real exam scenarios.</li>
//     <li>Earn Rewards: Top performers receive exciting prizes.</li>
//     <li>Competitive Edge: Foster a spirit of healthy competition.</li>
//     <li>Consistent Effort: Encourage continuous improvement and dedication.</li>
//   </ul>
//        </div>
//    </div>
//    </CardContent>
//    </Cards>
//    </Zoom>
//    <Zoom>
//    <Cards variant="outlined" style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center",margin:20,height:width>600?450:600,background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
//       <CardContent style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center"}}>
//    <div style={{width:width>600?300:80+"%",padding:10,display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
//     <div style={{textAlign:"center",color:"black",fontSize:25,fontWeight:"bold",fontFamily:"Bolden"}}>Weekly Reports on progress and personal consultation</div>
//     <div style={{fontSize:15,color:"black",textAlign:"center",marginTop:4+"%"}}>
//     <ul style={{ textAlign: "left", paddingLeft: 0 }}>
//     <li>In-Depth Analysis: Get detailed reports on strengths and areas for improvement.</li>
//     <li>Expert Consultations: Receive tailored advice from experienced professionals.</li>
//     <li>Effective Strategies: Develop customized study plans.</li>
//     <li>Continuous Motivation: Stay driven and focused on your goals.</li>
//     <li>Achieve Excellence: Maximize your potential with personalized guidance.</li>
//   </ul>
//        </div>
//    </div>
//    </CardContent>
//    </Cards></Zoom>

// </div>

// <div ref={story} style={{marginBottom:5+"%"}}>
// <TeamComponent />
// </div>
// <div ref={story} style={{marginBottom:5+"%"}}>
// <ArticleSection />
// </div>
// <div ref={contactus} style={{marginBottom:5+"%",width:100+"vw"}}>
// <ContactForm />
// </div>
// <div style={{width:100+"vw"}}>
// <Footer></Footer>
// </div>
// </div>
//  </div>
// </div>
// :
// <div style={{background:"linear-gradient(to bottom, #001F3F, #1E90FF)",display:"flex",width:100+"%",height:100+"vh",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
// <div class="loader"></div>
// <div style={{color:"white",fontSize:25,fontWeight:"bold",margin:20}}>Loading please wait...</div>
// </div>}
// </Route>
// </Switch>
// </div>
// </Router>:<LoadingComponent></LoadingComponent>}
// </div>
// );

// }
// export default Root;





// {/* <div ref={product} style={{display:"flex",flexDirection:width>600?"row":"column",justifyContent:"space-between",alignItems:"flex-start",width:width>600?90+"%":100+"%",marginBottom:5+"%",marginTop:width>600?5:0+"%", background:"white"}}>
// <Bounce>


// <Cards  variant="outlined" style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center", margin: 20, height: width > 600 ? 450 : 600 , background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
// <CardContent style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
//   <div style={{ 
//     width: width > 600 ? 300 : "80%", 
//     padding: 10, 
//     display: "flex", 
//     alignItems: "center", 
//     justifyContent: "center", 
//     flexDirection: "column" 
//   }}>
//     <div style={{ 
//       textAlign: "center", 
//       color: "black", 
//       fontSize: 20, 
//       fontWeight: "bold", 
//       fontFamily: "Bolden" 
//     }}>
//       150 CAT RCs Curated by 99.9+ percentilers
//     </div>
//     <div style={{ 
//       fontSize: 15, 
//       color: "black", 
//       textAlign: "center", 
//       marginTop: "4%" 
//     }}>
//       <ul style={{ textAlign: "left", paddingLeft: 0 }}>
//         <li>Exclusive Collection: Access a curated set of 150 Reading Comprehension passages.</li>
//         <li>Meticulously Crafted: Carefully selected by 99.9+ Percentile CAT Scorers.</li>
//         <li>Tailored for CAT Aspirants: Designed to meet the needs of CAT exam preparation.</li>
//         <li>Highest Quality: Ensure top-notch content for effective study.</li>
//         <li>Relevant Content: Focus on topics relevant to the CAT exam.</li>
//         <li>Comprehensive Preparation: Cover a wide range of passages from philosophy to economics to enhance skills.</li>
//        </ul>
//     </div>
//   </div>
// </CardContent>

//       </Cards>
//       </Bounce>
//       <Bounce>
//    <Cards variant="outlined" style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center",margin:20,height:width > 600 ? 450 : 600,background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
//    <CardContent style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
//   <div style={{ 
//     width: width > 600 ? 300 : "80%", 
//     padding: 10, 
//     display: "flex", 
//     alignItems: "center", 
//     justifyContent: "center", 
//     flexDirection: "column" 
//   }}>
//     <div style={{ 
//       textAlign: "center", 
//       color: "black", 
//       fontSize: 20, 
//       fontWeight: "bold", 
//       fontFamily: "Bolden" 
//     }}>
//       Innovative and first of its kind social platform
//     </div>
//     <div style={{ 
//       fontSize: 15, 
//       color: "black", 
//       textAlign: "center", 
//       marginTop: "4%" 
//     }}>
//       <ul style={{ textAlign: "left", paddingLeft: 0 }}>
//       <li>Auto-Post Scores: Seamlessly share your test results.</li>
//     <li>Expert Guidance: Tag founders for personalized advice.</li>
//     <li>Engage & Discuss: Participate in vibrant discussions.</li>
//     <li>Connect with Peers: Meet fellow aspirants and share insights.</li>
//     <li>Stay Motivated: Collaborate to strengthen and overcome challenges.</li>
//     <li>Boost Your Prep: Elevate your preparation like never before!</li>
//       </ul>
//     </div>
//   </div>
// </CardContent>
//    </Cards>
//    </Bounce>
//    <Bounce>
//    <Cards variant="outlined" style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center",margin:20,height:width>600?450:600,background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
//       <CardContent style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center"}}>
//    <div style={{width:width>600?300:80+"%",padding:10,display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
//     <div style={{textAlign:"center",color:"black",fontSize:20,fontWeight:"bold",fontFamily:"Bolden"}}>AI Predicted Percentile with your preparation data</div>
//     <div style={{fontSize:15,color:"black",textAlign:"center",marginTop:4+"%"}}>
      
//     <ul style={{ textAlign: "left", paddingLeft: 0 }}>
//     <li>Machine Learning Power: Advanced algorithms analyze your data.</li>
//     <li>Comprehensive Evaluation: Assess study hours, topic proficiency, and practice test scores.</li>
//     <li>Personalized Predictions: Receive a tailored percentile estimate.</li>
//     <li>Peer Benchmarking: Understand your ranking among peers.</li>
//     <li>Insightful Analysis: Get clear insights into your potential performance.</li>
//   </ul>
//    </div>
//    </div>
//    </CardContent>
//    </Cards></Bounce>

// </div>


// <div ref={product} style={{display:"flex",flexDirection:width>600?"row":"column",justifyContent:"space-between",alignItems:"flex-start",width:width>600?90+"%":100+"%",marginBottom:5+"%",marginTop:width>600?5:0+"%", background:"white"}}>
// <Bounce>
// <Cards  variant="outlined" style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center", margin: 20, height: width > 600 ? 450 : 600 , background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
//         <CardContent style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center", }}>
//           <div style={{ width: width > 600 ? 300 : 80 + "%", padding: 10, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
//             <div style={{ textAlign: "center", color: "black", fontSize: 25, fontWeight: "bold", fontFamily: "Bolden" }}>Personal mentoring and tips by 99 percentilers</div>
//             <div style={{ fontSize: 15, color: "black", textAlign: "center", marginTop: 4 + "%" }}>
//             <ul style={{ textAlign: "left", paddingLeft: 0 }}>
//     <li>Top Scorer Strategies: Learn from the best in the field.</li>
//     <li>Detailed Guidance: Approach each Reading Comprehension passage and paragraph with confidence.</li>
//     <li>Enhanced Comprehension: Sharpen your skills and pinpoint key details.</li>
//     <li>Effective Time Management: Master the art of pacing through tips.</li>
//     <li>Remote Learning Advantage: Gain valuable insights without in-person teaching.</li>
//   </ul>
//             </div>
//           </div>
//         </CardContent>
//       </Cards>
//       </Bounce>
//       <Bounce>
//    <Cards variant="outlined" style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center",margin:20,height:width>600?450:600,background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
//       <CardContent style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center"}}>
//    <div style={{width:width>600?300:80+"%",padding:10,display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
//    <div style={{textAlign:"center",color:"black",fontSize:25,fontWeight:"bold",fontFamily:"Bolden"}}>All India level competitions with rewards</div>
//     <div style={{fontSize:15,color:"black",textAlign:"center",marginTop:4+"%"}}>
//     <ul style={{ textAlign: "left", paddingLeft: 0 }}>
//     <li>Nationwide Challenges: Compete in All India Level Competitions.</li>
//     <li>Simulated Exam Conditions: Tackle challenging questions under real exam scenarios.</li>
//     <li>Earn Rewards: Top performers receive exciting prizes.</li>
//     <li>Competitive Edge: Foster a spirit of healthy competition.</li>
//     <li>Consistent Effort: Encourage continuous improvement and dedication.</li>
//   </ul>
//        </div>
//    </div>
//    </CardContent>
//    </Cards>
//    </Bounce>
//    <Bounce>
//    <Cards variant="outlined" style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center",margin:20,height:width>600?450:600,background: "linear-gradient(180deg, #ffffff 0%, #d8c3dc 100%)",padding:width>600?16:4}}>
//       <CardContent style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center"}}>
//    <div style={{width:width>600?300:80+"%",padding:10,display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
//     <div style={{textAlign:"center",color:"black",fontSize:25,fontWeight:"bold",fontFamily:"Bolden"}}>Weekly Reports on progress and personal consultation</div>
//     <div style={{fontSize:15,color:"black",textAlign:"center",marginTop:4+"%"}}>
//     <ul style={{ textAlign: "left", paddingLeft: 0 }}>
//     <li>In-Depth Analysis: Get detailed reports on strengths and areas for improvement.</li>
//     <li>Expert Consultations: Receive tailored advice from experienced professionals.</li>
//     <li>Effective Strategies: Develop customized study plans.</li>
//     <li>Continuous Motivation: Stay driven and focused on your goals.</li>
//     <li>Achieve Excellence: Maximize your potential with personalized guidance.</li>
//   </ul>
//        </div>
//    </div>
//    </CardContent>
//    </Cards></Bounce>

// </div> */}