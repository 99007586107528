// Modal.js
import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import Lottie from 'lottie-react';
import rewardAnimation from '../images/reward.json';

const CustomModal = ({ isOpen, onClose }) => {
  return (
    <Modal
    open={isOpen}
    onClose={onClose}
    aria-labelledby="reward-modal-title"
    aria-describedby="reward-modal-description"
  >
    <Box sx={modalStyles.modalContainer}>
      <Lottie animationData={rewardAnimation} style={modalStyles.lottie} />
      <Typography id="reward-modal-description" sx={modalStyles.modalText}>
        Earn real cash rewards for scoring 100% on three consecutive tests. Update and Check the app for wallet in the settings screen to withdraw your earnings.
      </Typography>
      <Button variant="contained" color="primary" onClick={onClose} sx={modalStyles.closeButton}>
        Close
      </Button>
    </Box>
  </Modal>
  );
};

const modalStyles = {
    modalContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    lottie: {
      width: 250,
      height: 250,
      marginBottom: 20,
    },
    modalText: {
      textAlign: 'center',
      marginBottom: 20,
      fontSize:20
    },
    closeButton: {
      mt: 2,
      backgroundColor:"#7f388b"
    },
  };

export default CustomModal;
