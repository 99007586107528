import React, { useState, useEffect } from 'react';
import { Card, Typography, CircularProgress, Button } from '@mui/material';
import axios from 'axios';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import perfAnimation from '../images/perf.json';
import durAnimation from '../images/dur.json';
import beginnerImg from '../images/beginner.png';
import intermediateImg from '../images/intermediate.png';
import masterImg from '../images/master_new.png';
import championImg from '../images/champion.png';
import { Mic } from '@mui/icons-material';

const TestResults = ({ token, user_id }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const history = useHistory(); 

  const fetchTestResults = async (page = 1, limit = 10) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const response = await axios.post('https://api.verboace.com/v1/users/mytests', { page, limit }, { headers });
      setResults(response.data.results);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching test results:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTestResults();
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const getBadgeImage = (score) => {
    if (score < 50) return beginnerImg;
    if (score >= 50 && score < 75) return intermediateImg;
    if (score >= 75 && score < 90) return masterImg;
    return championImg;
  };

  const handleApiCall = async (rcid) => {
    try {
        setLoading2(true)
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const response = await axios.post('https://api.verboace.com/v1/users/rcresponses', {
        rc_id: rcid,
        user_id: user_id
      }, { headers });
      setLoading2(false)
      history.push({
        pathname: '/solution',
        state: { data: response.data }, // Pass response data to the solution page
      });
    } catch (error) {
        setLoading2(false)
      console.error('Error calling rcresponses API:', error);
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ padding: '20px', width: 300 }}>
      <Typography variant="h4" gutterBottom>
        Your Last 10 Tests
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {results.map((result) => (
          <Card key={result.rc_id} style={styles.card}>
            <Typography variant="h6" gutterBottom>
              {result.title}
            </Typography>
            <div style={styles.info}>
              <div style={styles.details}>
                <div style={styles.score}>
                  <p>Score</p>
                  <Lottie options={{ animationData: perfAnimation }} height={30} width={30} />
                  <p style={{fontWeight: "bold"}}>{parseFloat(result.percentage).toFixed(2) + "%"}</p>
                </div>
                <div style={styles.duration}>
                  <p>Duration</p>
                  <Lottie options={{ animationData: durAnimation }} height={30} width={30} />
                  <p style={{fontWeight: "bold"}}>{formatTime(result.time_taken)}</p>
                </div>
              </div>
            </div>
            <div style={{display:"flex",justifyContent:"space-evenly",alignItems:"center",flexDirection:"row"}}>
            <Typography variant="body2" color="textSecondary" style={styles.date}>
              Date: {new Date(result.created_at).toLocaleDateString()} 
            </Typography>
            {result.mentormessage && <Mic style={{color:"red",justifyContent:"center",alignItems:"center",marginTop:10,fontSize:20}}></Mic>}
            </div>

              
            {loading2 ? <CircularProgress size={10}></CircularProgress>:<Button
              variant="contained"
              color="primary"
              style={{ marginTop: 10, backgroundColor: "#7f388b" }}
              onClick={() => handleApiCall(result.rc_id)}
            >
              View Solutions
            </Button>}

           
          </Card>
        ))}
      </div>
    </div>
  );
};

const styles = {
  card: {
    padding: '20px',
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  info: {
    display: 'flex',
    marginTop: '10px'
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  score: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    borderStyle: 'solid',
    padding: '4px 16px',
    borderColor: '#eeeeee',
    borderRadius: 5,
    height: 100,
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    borderStyle: 'solid',
    padding: '8px 16px',
    borderColor: '#eeeeee',
    borderRadius: 5,
    height: 100,
  },
  date: {
    marginTop: '10px',
    textAlign: 'center',
    fontSize: 15,
  },
};

export default TestResults;
