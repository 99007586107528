import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import Test from './Test';
// import Wall from './Wall';
import { QuizProvider } from './quizContext';
import { GoogleOAuthProvider } from '@react-oauth/google';


function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId="346911359027-nsuu9mph8v2naj8fhvpge9sfnsjar1pn.apps.googleusercontent.com">
      <Router>
        <Switch>
          {/* <Route path="/" exact component={Wall} />
          <Route path="/test" component={Test} /> */}
        </Switch>
      </Router>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;