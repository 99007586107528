import { Avatar, Button, Card, CircularProgress, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import verboace from './../images/verboace.png';
import { useQuizContext } from '../quizContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { connect, useDispatch } from 'react-redux';
import '../App.css';
import { apiendpoint } from '../endpoint';
import axios from 'axios';
import MyCard from './myCard';
import ProfileCard from './profileCard';
import ContentLoader from 'react-content-loader';
import { logout } from '../authActions';
import ConversationModal from './ConversationModal';
import ContentCard from './contentCard';
import CustomModal from './customModal';
import Countdown from 'react-countdown';
import rewardAnimation from '../images/timer.json';
import Lottie from 'lottie-react';
import TestResults from './testResults';
import ReadingGame from './readingGame';


function NotificationsBox({ token }) {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
   
  
    useEffect(() => {
      // Fetch the notifications from the API
      const fetchNotifications = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            };
          const response = await axios.post(`${apiendpoint.path}/v1/users/comments`,{}, {
            headers,
            
          });
          console.log(response.data)
          setNotifications(response.data.comments);
        } catch (error) {
          console.error("Error fetching notifications", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchNotifications();
    }, [token]);
  
    if (loading) {
      return <CircularProgress />;
    }
  
    return (
      <Card style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h6" gutterBottom>
         Last 10 Notifications
        </Typography>
        {notifications.map((notification) => (
          <Card key={notification.id} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Avatar src={notification.display_pic} alt={notification.display_name} style={{ margin: '15px',height:32,width:32 }} />
            <div>
              <Typography variant="body1" style={{textAlign:"left",padding:8}}>
                {notification.comment}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{padding:4}}>
                {new Date(notification.created_at).toLocaleString()}
              </Typography>
            </div>
          </Card>
        ))}
      </Card>
    );
  }
  

function Dashboard({ isLoggedIn, user, token }) {
    const examDate = new Date('2024-11-24T00:00:00');
    const { duration, setDuration, pallette, setPallette, completed, setCompleted, score, setScore, login, setLogin } = useQuizContext();
    const navigation = useHistory();
    const [data, setData] = useState(null);
    const [dataTest, setDataTest] = useState(null);
    const [loading, setLoading] = useState(true);
    const [testloading, settestloading] = useState(false);
    const [modal, setModal] = useState(false);
    const [showInstallModal, setShowInstallModal] = useState(false);
    const [weakAreas, setWeakAreas] = useState([]);
    const [isRandomAreas, setIsRandomAreas] = useState(false);
    const [selectedRcId, setSelectedRcId] = useState(null);
    const [loadingWeakAreas, setLoadingWeakAreas] = useState(false);
      // State for installation modal
    
    const [weakArea, setWeakArea] = useState(null);
    const [weakCategory, setweakCategory] = useState(null);
    const dispatch = useDispatch()
    const [notifications, setNotifications] = useState([]);

 

    useEffect(() => {
        if (!isLoggedIn) {
            navigation.replace("/");
        }
    }, [isLoggedIn]);


    useEffect(() => {
        const hasShownModal = localStorage.getItem('hasShownModal');
        if (!hasShownModal) {
            setShowInstallModal(true);
            localStorage.setItem('hasShownModal', 'true');
        }
    }, []);

    const fetchTest = async () => {
        try {
            settestloading(true)
            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            };

            const postData = selectedRcId ? { rcId: selectedRcId } : {};

            const response = await axios.post(apiendpoint.path + "/v1/users/getfreshrcweb", postData, { headers });
            console.log(response);
            setDataTest(response.data.rcDetails)
            if(response.data.weakAreamain==null||response.data.weakCategorymain==null){
                navigation.push('/testing',{data:response.data.rcDetails})
            }
            else{
                setWeakArea(response.data.weakAreamain)
                setweakCategory(response.data.weakCategorymain)
                setModal(true)
            }
            // navigation.push('/testing',{data:response.data.rcDetails})
            settestloading(false);
        } catch (error) {
            dispatch(logout(
            )); 
            navigation.replace('/')
            console.error('Error fetching posts:', error);
            settestloading(false);
        }
    };


    const fetchWeakAreas = async () => {
        setLoadingWeakAreas(true);
        try {
          const headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          };
          const response = await axios.get(`${apiendpoint.path}/v1/users/weakareas`, { headers });
          setWeakAreas(response.data.weakAreas);
          setIsRandomAreas(response.data.isRandom);
        } catch (error) {
          console.error('Error fetching weak areas:', error);
        } finally {
          setLoadingWeakAreas(false);
        }
      };
    


      const renderWeakAreasSection = () => {
        if (loadingWeakAreas) {
          return <CircularProgress color={"primary"} style={{margin:10,color:"#7f388b"}} />;
        }
    
        const message = isRandomAreas
          ? "There isn't enough data for the AI Agent to pick your weaknesses, so these are the most asked areas in CAT:"
          : "Verboace coach (AI Agent) picked these as your weak areas from your preparation data:";
    
        return (
          <div style={styles.weakAreasSection}>
            <Typography variant="body1" style={styles.weakAreasMessage}>{message}</Typography>
            <Typography variant="body1" style={styles.weakAreasMessage}> select the area if you want to take the test from a particular area</Typography>
        
            <div style={styles.weakAreasButtons}>
              {weakAreas.map((area) => (
                <Button
                  key={area.rcId}
                  variant={selectedRcId === area.rcId ? "contained" : "outlined"}
                  onClick={() => setSelectedRcId(area.rcId)}
                  style={styles.weakAreaButton}
                >
                  {area.area} {selectedRcId === area.rcId && "✓"}
                </Button>
              ))}
            </div>
          </div>
        );
      };

    // useEffect(()=>{
    //     if(weakArea!=null && weakCategory!=null){
       
    //     }
    // },[weakArea,weakCategory])


    const onClose = async()=>{
        setModal(false)
        navigation.push('/testing',{data:dataTest})
    }

    const fetchPosts = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            };

            const postData = {
                page: 1,
                limit: 25,
                onlyMine: false,
                bookmarks: false
            };

            const response = await axios.post(apiendpoint.path + "/v1/users/posts", postData, { headers });
            setData(response.data.posts);
            setLoading(false);
        } catch (error) {
            dispatch(logout(
            )); 
            navigation.replace('/')
            console.error('Error fetching posts:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
       if(token && isLoggedIn){
            fetchWeakAreas();
            fetchPosts();
          }   
 
    }, []);

    

    const ShimmerLoader = () => (
        <ContentLoader
            speed={2}
            width={400}
            height={160}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="5" ry="5" width="400" height="160" />
        </ContentLoader>
    );

    return (
        <div className="App" style={styles.appContainer}>
          {/* Left Column: Test Results */}
          <div className="left-column" style={styles.leftColumn}>
            {token && <TestResults token={token} user_id={user.user_id} />}
           
          </div>
      
          {/* Center Column: Shimmer Layout */}
          <div className="center-column" style={styles.centerColumn}>
               {!loading  &&  user && user.verified && (
              <Button
                disabled={testloading}
                color={"#7f388b"}
                variant="primary"
                style={styles.reactButton}
                onClick={() => { navigation.push('/admin') }}>
                {"Admin"}
              </Button>
            )}

{renderWeakAreasSection()}

{!loading  &&    <Button
              disabled={testloading}
              color={"#7f388b"}
              variant="primary"
              style={styles.reactButton}
              onClick={() => { fetchTest() }}>
              {testloading ? "Loading..." : "Take the test"}
            </Button>}

            {!loading  &&  <ReadingGame token={token} />}
          
            {loading ? (
              <div>
                <ShimmerLoader />
                <ShimmerLoader />
                <ShimmerLoader />
                <ShimmerLoader />
                <ShimmerLoader />
                <ShimmerLoader />
              </div>
            ) : (
              data && data.length > 0 ? (
                data.map((post, index) => (
                  post.type === "test" ? <MyCard key={index} data={post} token={token} /> : <ContentCard key={index} data={post} token={token} />
                ))
              ) : (
                <Typography variant="h6">No posts available</Typography>
              )
            )}
          </div>
      
          {/* Right Column: Profile and Countdown Timer */}
          <div className="right-column" style={styles.rightColumn}>
          <Card style={styles.timerCard}>
              <Lottie animationData={rewardAnimation} style={styles.lottie} />
              <Typography variant="h4">CAT Exam Countdown</Typography>
              <Countdown
                date={examDate}
                renderer={({ days, hours, minutes, seconds, completed }) => (
                  <div>
                    {completed ? (
                      <Typography variant="h6">The exam has started!</Typography>
                    ) : (
                      <Typography variant="h6" style={{ marginTop: 10, fontWeight: "bold" }}>
                        {days} Days {hours} Hours {minutes} Minutes {seconds} Seconds
                      </Typography>
                    )}
                  </div>
                )}
              />
            </Card>
          {token && <NotificationsBox token={token} />}
            {user && (
              <ProfileCard
                name={user.display_name}
                image={user.display_pic}
                title={user.email}
                tokens={user.tokens}
              />
            )}
          
          </div>
      
          {/* Modals */}
          <ConversationModal
            isOpen={modal}
            onClose={onClose}
            weakArea={weakArea}
            weakCategory={weakCategory}
          />
      
          <CustomModal
            isOpen={showInstallModal}
            onClose={() => setShowInstallModal(false)}
          />
        </div>
      );
      
}

const styles = {
    appContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems:"flex-start",
      padding: '25px',
      marginTop: '0px', // Ensure enough space at the top
      overflowY: 'auto'// Allow scrolling if content exceeds screen height
    },
    leftColumn: {
      flex: 1,
      marginRight: '20px',
    },
    centerColumn: {
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    rightColumn: {
      flex: 1,
      marginLeft: '20px',
      marginTop: '20px',
    },
    reactButton: {
      marginTop: '10px',
      width: 600,
      backgroundColor: "#7f388b",
      fontWeight: "bold",
      padding: 4,
      color: "white",
      fontSize: 16,
    },
    timerCard: {
      padding: '16px',
      marginTop: '20px',
      marginBottom: '20px',
      textAlign: 'center',
      backgroundColor: '#fff',
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    lottie: {
      width: 25,
      height: 25,
      marginBottom: 20,
      justifyContent: "center",
      alignItems: "center",
    },
    weakAreasSection: {
        marginBottom: '20px',
        width: '100%',
      },
      weakAreasMessage: {
        marginBottom: '5px',
        fontWeight: 'bold',
        marginTop:5
      },
      weakAreasButtons: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      },
      weakAreaButton: {
        margin: '5px',
        minWidth: '120px',
      },
  };

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
    token: state.auth.token
});

export default connect(mapStateToProps)(Dashboard);
