import React, { useState } from 'react';
import { Card, Typography, Button } from '@mui/material';
import { ThumbDownSharp, ThumbUpSharp } from '@mui/icons-material';
import { apiendpoint } from '../endpoint';

const FeedbackComponent = ({ rcId, token }) => {
    const [feedbackGiven, setFeedbackGiven] = useState(false);
  
    const sendFeedback = async (reaction) => {
      try {
        const response = await fetch(apiendpoint.path+'/v1/users/feedback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            rc_id: rcId,
            reaction: reaction
          })
        });
  
        if (response.ok) {
          setFeedbackGiven(true);
        } else {
          throw new Error('Failed to send feedback');
        }
      } catch (error) {
        console.error('Error sending feedback:', error);
      }
    };
  
    if (feedbackGiven) {
      return null;
    }
  
    return (
      <Card elevation={3} style={{ marginTop: '20px', padding: '20px' }}>
        <Typography variant="h6" gutterBottom>Your Feedback on Voice mentor Message</Typography>
        <Typography variant="body1" gutterBottom>Was this helpful ?</Typography>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
          <Button 
            onClick={() => sendFeedback('like')}
            startIcon={<ThumbUpSharp />}
            variant="outlined"
            color="secondary"
          >
            Like
          </Button>
          <Button 
            onClick={() => sendFeedback('dislike')}
            startIcon={<ThumbDownSharp />}
            variant="outlined"
            color="secondary"
          >
            Dislike
          </Button>
        </div>
      </Card>
    );
  };

  export default FeedbackComponent;