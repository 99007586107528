import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import Root from './Root';
import Header1 from './Comp/Header1';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Wordsstories from './Comp/Wordsstories';
import Signup from './Signup';
import Authpage from './Authpage';
import Feature1 from './Comp/feature1';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {persistor, store} from './store';
import { PersistGate } from 'redux-persist/integration/react';


ReactDOM.render(
<GoogleOAuthProvider clientId="346911359027-nsuu9mph8v2naj8fhvpge9sfnsjar1pn.apps.googleusercontent.com">
<Provider store={store}>
<PersistGate loading={null} persistor={persistor}>
<Root></Root>
</PersistGate>
</Provider>
</GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

