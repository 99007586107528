import React, { useEffect } from 'react';
import styled from 'styled-components';

const Card = styled.div`
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 20px 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 25px;
  height: 450px;
  justify-content: space-evenly;
  border-radius:10px;
`;

// Styled components for the React component
const ResponsiveContainerBlock = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; // This adds consistent spacing between cards
  margin: 0 auto;
  padding: 0 20px;
  align-items:center; // Add some padding on the sides
`;

const TeamHeadText = styled.p`
  font-size: 48px;
  font-weight: 900;
  text-align: center;
  line-height: 50px;
  width: 100%;
  margin: 0;
  color:#7f388b;
  margin-bottom: 50px;
`;

const Container = styled.div`
  max-width: 1380px;
  margin: 60px auto;
  padding: 0 30px;
`;

const CardContainer = styled.div`
  width: 220px;
  margin: 0 10px 25px;

  @media (max-width: 768px) {
    width: 350px;
    display:flex;
    jusitfy-content:center;
    align-items:center;
    margin-left:16%;
   
  }
`;



const TextBlk = styled.p`
  margin: 0;
  line-height: 25px;
`;

const Name = styled.p`
  margin: 20px 0 5px;
  font-size: 18px;
  font-weight: 800;
`;

const FeatureText = styled.p`
  margin: 0;
  margin-bottom: 20px;
  color: rgb(122, 122, 122);
  line-height: 30px;
`;

const TeamMemberImage = styled.img`
  max-width: 100%;
`;

const SocialIcons = styled.div`
  width: 70px;
  display: flex;
  justify-content: space-between;
`;

const TwitterIcon = styled.img`
  /* Add your specific styles for Twitter icon */
`;

const FacebookIcon = styled.img`
  /* Add your specific styles for Facebook icon */
`;

// React component
const TeamComponent = () => {
  useEffect(() => {
    const update = ({ x, y }) => {
      document.documentElement.style.setProperty('--x', x.toFixed(2));
      document.documentElement.style.setProperty('--xp', (x / window.innerWidth).toFixed(2));
      document.documentElement.style.setProperty('--y', y.toFixed(2));
      document.documentElement.style.setProperty('--yp', (y / window.innerHeight).toFixed(2));
    };

    document.body.addEventListener('pointermove', update);

    return () => {
      document.body.removeEventListener('pointermove', update);
    };
  }, []);
  return (
    <Container>
      <TeamHeadText>Our Team</TeamHeadText>
      <ResponsiveContainerBlock>
        <CardContainer className="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12" >
          <Card style={{width:250}}>
            <div className="team-image-wrapper">
              <TeamMemberImage src="https://storage.googleapis.com/assets_verboace/DSC_7279.JPG" alt="Rahul Mattihalli" style={{borderRadius:50+"%",height:150,width:150,objectFit:"cover"}} />
            </div>
            <TextBlk className="name" style={{margin:10,fontWeight:"bold",fontSize:20,marginTop:20}}>Rahul Mattihalli</TextBlk>
            <TextBlk className="position">CTO</TextBlk>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center", marginTop:10}}>
            <TextBlk >Student at:</TextBlk>
            <TeamMemberImage src="https://storage.googleapis.com/assets_verboace/xl%20(2).png" alt="Rahul Mattihalli" style={{height:60,width:200,objectFit:"contain",marginTop:-10}} />
            </div>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <TextBlk style={{marginRight:0}}>Previously:</TextBlk>
           <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
            <TeamMemberImage src="https://storage.googleapis.com/assets_verboace/netskope.png" alt="Rahul Mattihalli" style={{height:50,width:100,objectFit:"contain",marginTop:-10}} />
            </div>
            </div>
            <SocialIcons>
            <a href="https://www.linkedin.com/in/rahulmattihalli" target="_blank">
                <FacebookIcon src="https://storage.googleapis.com/assets_verboace/icons8-linkedin-144.png" alt="Facebook icon"  style={{height:25,width:25}}/>
              </a>
              <a href="https://www.twitter.com" target="_blank">
                <TwitterIcon src="https://storage.googleapis.com/assets_verboace/icons8-x-150.png" alt="Twitter icon"  style={{height:25,width:25}}/>
              </a>
            
            </SocialIcons>
          </Card>
        </CardContainer>
        <CardContainer className="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12">
          <Card style={{width:250}}>
            <div className="team-image-wrapper">
              <TeamMemberImage src="https://storage.googleapis.com/assets_verboace/1702502775961.jpg" alt="Rahul Mattihalli" style={{borderRadius:50+"%",height:150,width:150,objectFit:"cover"}} />
            </div>
            <TextBlk className="name" style={{margin:10,fontWeight:"bold",fontSize:20,marginTop:20}}>Vinay Mehta</TextBlk>
            <TextBlk className="position">CMO</TextBlk>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center", marginTop:10}}>
            <TextBlk >Student President at:</TextBlk>
            <TeamMemberImage src="https://storage.googleapis.com/assets_verboace/IIM_Calcutta_Logo.svg.png" alt="Rahul Mattihalli" style={{height:50,width:150,objectFit:"contain",marginTop:5}} />
            </div>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:55}}>
            <SocialIcons style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <a href="https://in.linkedin.com/in/vinay-mehta-5063261b3" target="_blank">
                <FacebookIcon src="https://storage.googleapis.com/assets_verboace/icons8-linkedin-144.png" alt="Facebook icon"  style={{height:25,width:25}}/>
              </a>

            </SocialIcons>
            </div>
           
          </Card>
        </CardContainer>

        <CardContainer className="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12">
          <Card style={{width:250}}>
            <div className="team-image-wrapper">
              <TeamMemberImage src="https://storage.googleapis.com/assets_verboace/1653674711061%20(1).jpg" alt="Rahul Mattihalli" style={{borderRadius:50+"%",height:150,width:150,objectFit:"cover"}} />
            </div>
            <TextBlk className="name" style={{margin:10,fontWeight:"bold",fontSize:20,marginTop:20}}>Harshith S K</TextBlk>
            <TextBlk className="position">COO</TextBlk>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center", marginTop:10}}>
            <TextBlk >Senior Engineer at:</TextBlk>
            <TeamMemberImage src="https://storage.googleapis.com/assets_verboace/pngwing.com.png" alt="Sony" style={{height:50,width:150,objectFit:"contain",marginTop:0}} />
            </div>
            
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:55}}>
            <SocialIcons style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <a href="https://in.linkedin.com/in/harshitsk" target="_blank">
                <FacebookIcon src="https://storage.googleapis.com/assets_verboace/icons8-linkedin-144.png" alt="Facebook icon"  style={{height:25,width:25}}/>
              </a>

            </SocialIcons>
            </div>
           
          </Card>
        </CardContainer>
        <CardContainer className="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12">
          <Card style={{width:250}}>
            <div className="team-image-wrapper">
              <TeamMemberImage src="https://storage.googleapis.com/assets_verboace/1718706020361.jpeg" alt="Darshan J" style={{borderRadius:50+"%",height:150,width:150,objectFit:"cover"}} />
            </div>
            <TextBlk className="name" style={{margin:10,fontWeight:"bold",fontSize:20,marginTop:20}}>Darshan J</TextBlk>
            <TextBlk className="position">Lead QA</TextBlk>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center", marginTop:10}}>
            <TextBlk >Senior Engineer at:</TextBlk>
            <TeamMemberImage src="https://storage.googleapis.com/assets_verboace/hashedin-logo.png" alt="Hashedin" style={{height:50,width:150,objectFit:"contain",marginTop:0}} />
            </div>
            
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:55}}>
            <SocialIcons style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <a href="https://in.linkedin.com/in/darshanjayadevappa" target="_blank">
                <FacebookIcon src="https://storage.googleapis.com/assets_verboace/icons8-linkedin-144.png" alt="Facebook icon"  style={{height:25,width:25}}/>
              </a>

            </SocialIcons>
            </div>
           
          </Card>
        </CardContainer>

      
        {/* Repeat the above CardContainer for other team members */}
       
      </ResponsiveContainerBlock>
    </Container>
  );
}

export default TeamComponent;
